import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { decryptData, Storage } from "../../Services/Storage";
import "./style.css";
import { Axios } from "../../Services/Axios";
import { useNavigate } from "react-router-dom";
import { fetchPermissions } from "../../Components/sidebar";
import { toast } from "react-toastify";
const Index = () => {
  const center = useSelector((e) => e.Center);
  const [isCheckOut, setIsCheckOut] = useState(false);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [centreName, setCentreName] = useState("");
  const [centreId, setCentreId] = useState("");
  const [permissions, setPermissions] = React.useState([]);
  const [remarks, setRemarks] = useState("");
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);
  const [semester, setSemester] = useState([]);
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);

  const [books, setBooks] = useState([]);
  const { centresId, role, id } = Storage.getItem("user") || {};
  const { centreName: cName } = Storage.getItem("center") || {};

  console.log(Storage.getItem("center"))
  const centers = [
    "252c0e2c-9335-43f0-bfec-08db9f09cf7c",
    "768decfa-2820-4814-bfed-08db9f09cf7c",
    "ff4d326e-2938-48cd-bfee-08db9f09cf7c",
    "d84c8f6d-28f0-418d-bfef-08db9f09cf7c",
    "603ab771-02d1-4dd3-bff0-08db9f09cf7c",
    "58c4794f-fed2-44d2-bff2-08db9f09cf7c",
    "4343d612-4071-4a24-bff4-08db9f09cf7c",
    "5107212c-48e5-4ccb-bff6-08db9f09cf7c",
    "717a7e9d-3af4-4a44-bff8-08db9f09cf7c",
  ];

  function isInCenters(id) {
    return centers.includes(id);
  }

  useEffect(() => {
    if ((role == "center", role == "employee")) {
      setCentreName(decryptData(center)?.centreName);
      setCentreId(decryptData(center)?.id);
    }

    let a = sessionStorage.getItem("book");
    let x = JSON.parse(a);
    if (x) {
      let v = x.filter((e) => e.checked != false);

      setBooks([...books, ...v]);
    }
  }, [center]);

  function getUniqueDataByUID(array1, array2, uidKey) {
    const map = new Map();

    // Add elements from array1 to the map
    for (const item of array1) {
      map.set(item[uidKey], item);
    }

    // Add elements from array2 to the map, overwriting any existing entries with the same UID
    for (const item of array2) {
      map.set(item[uidKey], item);
    }

    // Return the values of the map, which contain the unique data
    return Array.from(map.values());
  }

  const handleCheckboxChange = (id) => {
    const updatedBooks = books.map((book) => {
      if (
        book.uid === id &&
        book.quantity !== "" &&
        !isNaN(book.quantity) &&
        parseInt(book.quantity) !== 0
      ) {
        return { ...book, checked: !book.checked };
      }
      return book;
    });

    let q = sessionStorage.getItem("book");
    let w = JSON.parse(q);
    const updatedstorage = w?.filter((book) => book.uid !== id);

    let y = updatedBooks.filter((e) => e.checked == true);

    let x = getUniqueDataByUID(updatedstorage ? updatedstorage : [], y, "uid");

    // let u = w?.filter((w)=> w.uid === y.uid);

    sessionStorage.setItem("book", JSON.stringify(x));

    setBooks(updatedBooks);
  };

  const handleQuantityChange = (id, quantity) => {
    const updatedBooks = books.map((book) =>
      book.uid === id ? { ...book, quantity: quantity } : book
    );
    setBooks(updatedBooks);
    // sessionStorage.setItem("book", JSON.stringify(updatedBooks));
    // setBooks(updatedBooks);
  };

  const handleProceed = () => {
    const selectedBooks = books.filter((book) => book.checked);
    // Do something with selectedBooks
    setSelectedBooks(selectedBooks);

    setIsCheckOut(true);
  };

  function hasCheckTrue(booksArray) {
    return booksArray.some((book) => book.check === true);
  }

  const handleFilterChange = (e) => {
    let a = e.target.value;
    if (a != "") {
      getSemester(a);
    }
  };

  const getBooksBySemester = (e) => {
    let a = e.target.value;
    if (a !== "") {
      // setBooks([]);
      setLoading(true);
      Axios.get(`/Book/GetbySemester?Id=${a}`).then(
        (res) => {
          setSelected(true);
          if (res.data.length > 0) {
            let bookinLocal = sessionStorage.getItem("book");
            let parsedBook = JSON.parse(bookinLocal);

            const updatedBooks = res.data.map((apiBook, i) => {
              const localBook = parsedBook?.find(
                (local) => local.uid === apiBook.id
              );
              if (localBook) {
                return {
                  uid: apiBook.id,
                  id: i + 1,
                  bookname: apiBook.bookName,
                  price: isInCenters(decryptData(center)?.id)
                    ? apiBook.priceRop
                    : apiBook.priceKhi,
                  quantity: localBook.quantity,
                  semester: apiBook.semester?.name,
                  checked: localBook.checked,
                  course: apiBook.course?.name,
                };
              } else {
                return {
                  uid: apiBook.id,
                  id: i + 1,
                  bookname: apiBook.bookName,
                  price: isInCenters(decryptData(center)?.id)
                    ? apiBook.priceRop
                    : apiBook.priceKhi,
                  quantity: "",
                  semester: apiBook.semester?.name,
                  checked: false,
                  course: apiBook.course?.name,
                };
              }
            });

            setBooks(updatedBooks);
          }

          setLoading(false);
        },
        () => { }
      );
    }
  };

  const getSemester = (id) => {
    Axios.get(`/Semester/GetByCourseId?id=${id}`).then((res) => {
      setSemester(res.data);
    });
  };

  const getCourses = () => {
    Axios.get(`/Course/GetAllDeleted`).then((res) => {
      let filteredData = res?.data.filter((e) => e.active === "Y");

      setCourses(filteredData);
    });
  };

  const fetchUserPermissions = () => {
    fetchPermissions(id)
      .then((res) => {
        const permission = res.find((item) => item.title == "ECM order");
        setPermissions(permission);
      })
      .catch((err) => {
        toast.error("Uncaught error");
      });
  };

  useEffect(() => {
    getCourses();
    fetchUserPermissions();
  }, []);

  if (isCheckOut) {
    let a = sessionStorage.getItem("book");
    let parsedBooks = JSON.parse(a);
    const total = parsedBooks.reduce((acc, book) => {
      return acc + book.price * book.quantity;
    }, 0);

    const handleFileChange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setFileName(file.name);
        setFile(file);
      } else {
        setFileName("");
      }
    };

    const handleCheckout = () => {
      setLoading(true);
      let x = sessionStorage.getItem("book");
      let parsed = JSON.parse(x);

      if (file) {
        let payloadx = {
          orderBy: cName,
          totalCount: parsed.length,
          orderTo: "Printers",
          currentStatus: "ordered",
          centresId: centresId,
          totalPrice: total,
          remarks: remarks,
        };

        let payload = parsed.map((book) => ({
          orderQTY: book.quantity,
          semester: book.semester,
          bookname: book.bookname,
          course: book.course,
          unitPrice: book.price,
          totalPrice: book.price * book.quantity,
        }));

        let x = Object.assign({}, payloadx, { orderItems: payload });

        const formData = new FormData();

        // Append payloadx properties to FormData
        Object.keys(payloadx).forEach((key) => {
          formData.append(key, payloadx[key]);
        });

        // Append orderItems array to FormData
        x.orderItems.forEach((item, index) => {
          Object.keys(item).forEach((key) => {
            formData.append(`orderItems[${index}][${key}]`, item[key]);
          });
        });

        // Append paymentSlip separately
        formData.append("paymentSlip", file);

        Axios.post("/Order/Add", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }).then(
          (res) => {
            setLoading(false);
            toast.success("Successfully ordered");
            sessionStorage.setItem("book", JSON.stringify([]));
            navigate("/orders");
          },
          (err) => {
            console.log(err);
          }
        );
      } else {
        alert("Please Provide Payment Slip");
        setLoading(false);
      }
    };

    return (
      <>
        <div className="mt-5">
          <button
            className="btn "
            onClick={() => {
              setIsCheckOut(false);
            }}
          >
            <i className="fa-solid fa-arrow-left" style={{ fontSize: 30 }}></i>
          </button>
        </div>

        <div className="checkout-container">
          <h1 className="checkout-heading">Checkout</h1>
          <div className="selected-books-container">
            <table className="selected-books-table">
              <thead>
                <tr>
                  <th>Book Name</th>
                  <th>Price</th>
                  <th>Quantity</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {parsedBooks?.map((book) => (
                  <tr key={book.id}>
                    <td>{book.bookname}</td>
                    <td>{book.price}</td>
                    <td>{book.quantity}</td>
                    <td>{book.price * book.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="con-abc">
            <div className="col-6">
              <div className="file-input-container">
                <input
                  type="file"
                  className="file-input"
                  accept="image/png, image/gif, image/jpeg"
                  id="file"
                  onChange={handleFileChange}
                />
                <label htmlFor="file" className="file-label">
                  {fileName ? fileName : "Payment Slip"}
                </label>
              </div>
              <small style={{ fontSize: 10, color: "red", fontWeight: "bold" }}>
                Only Accept JPG,PNG,JPEG
              </small>
            </div>
            <div className="col-7">
              <textarea
                name="Remarks"
                id=""
                className="remarks"
                placeholder="Remarks"
                onChange={(e) => setRemarks(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="total-section">
            <h2 className="total-text">Total: {total}</h2>
            <button
              className="btn btntheme"
              onClick={handleCheckout}
              disabled={loading}
            >
              {loading ? "Loading..." : " Check Out"}
            </button>
          </div>
        </div>
      </>
    );
  }

  const handleDelete = (uid) => {
    let filtered = books.filter((e) => e.uid != uid);
    sessionStorage.setItem("book", JSON.stringify(filtered));

    console.log(filtered);
    setBooks(filtered);
  };

  return (
    <div className="container">
      <label htmlFor="" style={{ marginRight: 12 }}>
        Select Course & Semester
      </label>
      <div
        className="mb-5 mt-2"
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <select
            name=""
            onChange={handleFilterChange}
            id="filterSelect"
            className="form-select"
          >
            <option value="">Select Course</option>
            {courses.map((e) => (
              <option value={e.id} key={e.id}>
                {e.name}
              </option>
            ))}
          </select>
          <select
            name=""
            id="filterSelect"
            className="form-select"
            onChange={getBooksBySemester}
            style={{ marginLeft: 10 }}
          >
            <option value="">Select Semester</option>
            {semester.map((e) => (
              <option value={e.id} key={e.id}>
                {e.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          {JSON.parse(sessionStorage.getItem("book"))?.length > 0 && (
            <button className="btn btntheme" onClick={handleProceed}>
              <i
                className="fa-solid fa-cart-shopping"
                style={{ marginRight: 5 }}
              ></i>
              Cart ({JSON.parse(sessionStorage.getItem("book"))?.length})
            </button>
          )}
        </div>
      </div>

      {books?.length > 0 && (
        <>
          <table className="custom-table">
            <thead>
              <tr>
                {/* <th></th> */}
                <th>ID</th>
                <th>Course</th> {/* New column for Semester */}
                <th>Semester</th> {/* New column for Semester */}
                <th>Book Name</th>
                <th>Price</th>
                <th>Quantity</th>
                {permissions.canAdd && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {books?.map((book, i) => (
                <tr key={book.id}>
                  {/* <td>
                    <input
                      className="custom-checkbox"
                      type="checkbox"
                      checked={book.checked}
                      onChange={() => handleCheckboxChange(book.uid)}
                      disabled={
                        book.quantity === "" ||
                        isNaN(book.quantity) ||
                        parseInt(book.quantity) === 0
                      }
                    />
                  </td> */}
                  <td>{i + 1}</td>
                  <td>{book.course}</td> {/* Display Semester */}
                  <td>{book.semester}</td> {/* Display Semester */}
                  <td>{book.bookname}</td>
                  <td>{book.price}</td>
                  <td>
                    <input
                      className="custom-input"
                      type="number"
                      min="1"
                      value={book.quantity}
                      onChange={(e) =>
                        handleQuantityChange(book.uid, e.target.value)
                      }
                    />
                  </td>
                  {permissions.canAdd && (
                    <td>
                      {!selected && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDelete(book?.uid)}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </span>
                      )}
                      {selected && (
                        <button
                          className="btn btntheme"
                          disabled={
                            book.quantity === "" ||
                            isNaN(book.quantity) ||
                            parseInt(book.quantity) === 0
                          }
                          onClick={() => handleCheckboxChange(book.uid)}
                        >
                          {JSON.parse(sessionStorage.getItem("book"))?.some(
                            (e) => e.uid === book.uid
                          )
                            ? "Remove from Cart"
                            : "Add to Cart"}
                        </button>
                      )}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default Index;