import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Login } from "../Pages/Auth/Login";
import { Centers } from "../Pages/Centers";
import { Cities } from "../Pages/Cities";
// import { Dashboard } from "../Pages/Dashboard";
import { Inquiry } from "../Pages/Inquiry";
import { POM } from "../Pages/POM";
import { Placements } from "../Pages/Placements";
import { Axios } from "../Services/Axios";
import { clear, decryptData, encryptData, Storage } from "../Services/Storage";
import { SOM } from "../Pages/SOM";
import { UpcomingBatches } from "../Pages/UpcomingBatches";
import { Events } from "../Pages/Events";
import OMS from "../Pages/OMS/Index";
import Statuses from "../Pages/Statuses/Index";
import Issues from "../Pages/Issues/Index";
import Tickets from "../Pages/Tickets/Index";
import OMSCENTER from "../Pages/OMSCENTER/Index";
import CHECKOUT from "../Pages/OMSCENTER/Checkout";
import Course from "../Pages/Courses/Index";
import Semester from "../Pages/Semester/Index";
import Orders from "../Pages/Orders/Index";
import Setting from "../Pages/Settings/Index";
import VenderOrders from "../Pages/Vendor/Orders";
import CenterOrders from "../Pages/OMSCENTER/Orders";
import Welcome from "../Pages/Welcome/index";
import { fetchPermissions } from "../Components/sidebar";
import { toast } from "react-toastify";

// import { Events } from "../Pages/Events/index";
// const routes = [
//   { path: "/", element: <Home value="Dashboard" /> },
//   { path: "/inquiry", element: <Inquiry value="Course Inquiry" /> },
//   { path: "/centre", element: <Centre value="Course Inquiry" /> },
//   { path: "/batches", element: <Batches value="" /> },
//   { path: "/som", element: <Som value="" /> },
//   { path: "/pom", element: <Pom value="" /> },
//   { path: "/placement", element: <Placement value="" /> },
//   { path: "/events", element: <Events value="" /> },
//   { path: "/videos", element: <Videos value="" /> },
//   { path: "/alumni", element: <Alumni value="" /> },
//   { path: "/survey", element: <Survey value="" /> },
//   { path: "/complaint", element: <Complains value="" /> },
//   { path: "/login", element: <Login value="" /> },
// ];
export const Router = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const auth = useSelector((e) => e.LoggedIn);
  const role = decryptData(useSelector((e) => e.Role));
  const [loaded, setLoaded] = useState(false);
  const [permissions, setPermissions] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const center = useSelector((e) => e.Center);

  const { id } = Storage.getItem("user") || {};

  useEffect(() => {
    setLoggedIn(decryptData(auth));

    setLoaded(true);
    if (id) {
      if (auth) {
        fetchPermissions(id)
          .then((res) => {
            const permission = res.filter((item) => item.canAdd);
            // console.log(permission);
            setPermissions(permission);
          })
          .catch((err) => {
            toast.error("Uncaught error");
          });
      }
    }
  }, [auth]);

  Axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error?.response?.status === 401) {
        console.log("Nothing just hanging around.");
        clear();
        dispatch({ type: "setLogin", payload: encryptData(false) });
        dispatch({ type: "setLoader", payload: encryptData(false) });
        dispatch({ type: "setUser", payload: null });
        dispatch({ type: "setRole", payload: null });
        navigate("/login");
      }

      return Promise.reject(error);
    }
  );
  return loaded ? (
    <>
      <Routes>
        {loggedIn ? (
          <>
            {role?.role === "admin" ? (
              <>
                <Route path="/" element={<Welcome />} />
                <Route path="/course-inquiry" element={<Inquiry />} />
                <Route path="/placements" element={<Placements />} />
                <Route path="/Pom" element={<POM />} />
                <Route path="/Som" element={<SOM />} />
                <Route path="/upcoming-batches" element={<UpcomingBatches />} />
                <Route path="/events" element={<Events />} />
                <Route path="/tickets" element={<Tickets />} />
                <Route path="/issues" element={<Issues />} />
                <Route path="/statuses" element={<Statuses />} />
                <Route path="/centers" element={<Centers />} />
                <Route path="/cities" element={<Cities />} />
                <Route path="/oms" element={<OMS />} />
                <Route path="/courses" element={<Course />} />
                <Route path="/semester" element={<Semester />} />
                <Route path="/orders" element={<Orders />} />
                <Route path="/settings" element={<Setting />} />
              </>
            ) : (
              <>
                {role?.role === "center" || role?.role == "employee" ? (
                  <>
                    <Route path="/" element={<Welcome />} />
                    <Route path="/course-inquiry" element={<Inquiry />} />
                    <Route path="/placements" element={<Placements />} />
                    <Route path="/Pom" element={<POM />} />
                    <Route path="/Som" element={<SOM />} />
                    <Route
                      path="/upcoming-batches"
                      element={<UpcomingBatches />}
                    />
                    <Route path="/tickets" element={<Tickets />} />
                    <Route path="/events" element={<Events />} />
                    <Route path="/oms-center" element={<OMSCENTER />} />
                    <Route path="/checkout" element={<CHECKOUT />} />
                    <Route path="/orders" element={<CenterOrders />} />
                    <Route path="/settings" element={<Setting />} />
                  </>
                ) : (
                  <>
                    {role?.role === "OMS" && (
                      <Route path="/" element={<VenderOrders />} />
                    )}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </>
  ) : (
    <></>
  );
};
