import React from 'react'
import Loader from './loader'
import Modal from './Modal'
import { Dialog } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'

const DeleteModal = ({modal, setModal, deleteFunc, loading}) => {
    return (
        <Modal
            open={modal}
            setOpen={setModal}
            width={'max-w-2xl'}
            content={
                <div className="sm:items-start">
                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                            <button
                                type="button"
                                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() => setModal(false)}
                            >
                                <span className="sr-only">Close</span>
                            </button>
                        </div>
                        <div className="sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg leading-6 font-medium text-gray-900 flex justify-between "
                                >
                                    <div className="flex space-x-2">
                                        <ExclamationTriangleIcon
                                            className="h-6 w-6 text-red-600"
                                            aria-hidden="true"
                                        />
                                        <span>Delete Status</span>
                                    </div>
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                                        onClick={() => setModal(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                    </button>
                                </Dialog.Title>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700  sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => deleteFunc()}
                            >
                                {loading ? (
                                    <>
                                        <Loader size={"size-4"} /> Deleteing...
                                    </>
                                ) : (
                                    "Delete"
                                )}
                            </button>
                            <button
                                type="button"
                                className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
                                onClick={() => setModal(false)}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            }
        />
    )
}

export default DeleteModal