import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { decryptData, Storage } from "../../Services/Storage";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Axios } from "../../Services/Axios";
import { useFormik } from "formik";
import ReactPaginate from "react-paginate";
import ImportBooks from "./Import";
import { fetchPermissions } from "../../Components/sidebar";

const Index = () => {
  const center = useSelector((e) => e.Center);
  const [courses, setCourses] = useState([]);
  const [semester, setSemester] = useState([]);
  const [selectedBook, setSelectedBook] = useState(null);

  const [formValues, setFormValues] = useState([]);
  const [filtered, setFiltered] = React.useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const pageCount = Math.ceil(formValues.length / 10);
  const [loading, setLoading] = useState(false);
  const [selectedSemester, setSelectedSemester] = useState("");
  const [permissions, setPermissions] = useState([]);
  const id = Storage.getItem("user").id;

  useEffect(() => {
    const endOffset = itemOffset + 10;
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    fetchPermissions(id)
      .then((res) => {
        const permission = res.find((item) => item.title == "Books");
        // console.log(permission);
        setPermissions(permission);
      })
      .catch((err) => {
        toast.error("Uncaught error");
      });
  }, []);

  useEffect(() => {
    if (Boolean(center)) {
      console.log(decryptData(center)?.id);
    }
  }, [center]);

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const initialValues = {
    id: null,
    courseId: "",
    semesterId: "",
    bookName: "",
    unitPrice: "",
    region: "",
    priceKhi: "",
    priceRop: "",
    pages: "",
  };

  const validationSchema = Yup.object().shape({
    courseId: Yup.string().required("This field is required..."),
    semesterId: Yup.string().required("This field is required..."),
    bookName: Yup.string().required("This field is required..."),
    // unitPrice: Yup.string().required("This field is required..."),
    priceKhi: Yup.string().required("This field is required..."),
    priceRop: Yup.string().required("This field is required..."),
    pages: Yup.string().required("This field is required..."),
    // region: Yup.string().required("This field is required..."),
  });
  const openModal = () => {
    if (open) {
      formik.resetForm();
    }
    setOpen(!open);
  };

  const openModal1 = () => {
    setOpen1(!open1);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const payload = new FormData();
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      payload.append(key, values[key]);
    }

    if (!values.id) {
      payload.delete("id");
      Axios.post("/Book/Add", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          resetForm();
          setOpen(!open);
          handleFilterMethod();
          toast.success("Data Updated successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    } else {
      Axios.put("/Book/Update", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          resetForm();
          setOpen(!open);
          handleFilterMethod();
          toast.success("Data Updated successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const getSemester = (id) => {
    Axios.get(`/Semester/GetByCourseId?id=${id}`).then((res) => {
      setSemester(res.data);
    });
  };

  const getCourses = () => {
    Axios.get(`/Course/GetAllDeleted`).then((res) => {
      let filteredData = res?.data.filter((e) => e.active === "Y");
      setCourses(filteredData);
    });
  };

  const GetBooks = () => {
    Axios.get(`/Book/GetAll`).then(
      (res) => {
        setFormValues(res.data);
      },
      () => {}
    );
  };

  useState(() => {
    getCourses();
  }, []);

  const handleFilterChange = (e) => {
    let a = e.target.value;
    if (a != "") {
      getSemester(a);
    }
  };

  const getBooksBySemester = (e) => {
    let a = e.target.value;
    if (a != "") {
      setSelectedSemester(a);
    }
  };

  const handleFilterMethod = () => {
    if (selectedSemester != "") {
      setFormValues([]);
      setLoading(true);
      Axios.get(`/Book/GetbySemester?Id=${selectedSemester}`).then(
        (res) => {
          setFormValues(res.data);
          setLoading(false);
        },
        () => {}
      );
    }
  };

  const Edit = (e) => {
    setOpen(true);
    formik.setValues(e);
  };

  return (
    <>
      <div className="pageTitle m-4">
        <div>
          <h3>Books</h3>
        </div>
        <div>
          {permissions.canAdd && (
            <>
              <button className="btn btntheme" onClick={openModal}>
                Add Book
              </button>
              <button className="btn btntheme ml-5" onClick={openModal1}>
                Import Book
              </button>
            </>
          )}
        </div>
      </div>
      <label htmlFor="" style={{ marginRight: 12 }} className="filter-label mx-4">
        Select Course & Semester
      </label>
      <div className="mb-5 mt-2 mx-4">
        <select
          name=""
          onChange={handleFilterChange}
          id="filterSelect"
          className="form-select"
        >
          <option value="">Select Course</option>
          {courses.map((e) => (
            <>
              <option value={e.id}>{e.name}</option>
            </>
          ))}
        </select>
        <select
          name=""
          id="filterSelect"
          className="form-select"
          onChange={getBooksBySemester}
          style={{ marginLeft: 10 }}
        >
          <option value="">Select Semester</option>
          {semester.map((e) => (
            <>
              <option value={e.id}>{e.name}</option>
            </>
          ))}
        </select>
        <button className="ml-5 btn btntheme" onClick={handleFilterMethod}>
          {loading ? "Please Wait..." : "Book List"}
        </button>
      </div>
      {loading && <p>Loading...</p>}
      {!loading && formValues.length !== 0 && (
        <div className="table">
          <div className="flex tableheader">
            <div>Book Name</div>
            <div>Course</div>
            <div>Semester</div>
            <div>Price KHI</div>
            <div>Price ROP</div>
            <div>Pages</div>
            {permissions.canUpdate || permissions.canDelete ? (
              <th>Action</th>
            ) : null}
          </div>
          <div className="tablebody">
            {!loading && formValues.length > 0 && (
              <>
                {filtered.map((e, i) => (
                  <div className="flex" key={i}>
                    <div>
                      <span className="my-des-alt">{e.bookName}</span>
                    </div>

                    <div>
                      <span className="my-des-alt">{e.course?.name}</span>
                    </div>

                    <div>
                      <span className="my-des-alt">{e.semester?.name}</span>
                    </div>

                    <div>
                      <span className="my-des-alt">{e.priceKhi}</span>
                    </div>

                    <div>
                      <span className="my-des-alt">{e.priceRop}</span>
                    </div>
                    <div>
                      <span className="my-des-alt">{e.pages}</span>
                    </div>

                    <div>
                      {permissions.canUpdate && (
                        <span className="mx-2" onClick={() => Edit(e)}>
                          <i className="fa-solid fa-pen-to-square"></i>
                        </span>
                      )}
                      {/* <span className="mx-2" onClick={() => Delete(e.id)}>
                           <i className="fa-solid fa-trash-can"></i>
                         </span> */}
                    </div>
                  </div>
                ))}
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="&raquo;"
                  onPageChange={handlePage}
                  pageRangeDisplayed={5}
                  pageCount={pageCount}
                  previousLabel="&laquo;"
                  renderOnZeroPageCount={null}
                  containerClassName="mars-pagination"
                  pageClassName="mars-page"
                  nextClassName="mars-page"
                  previousClassName="mars-page"
                  activeClassName="active"
                />
              </>
            )}
          </div>
        </div>
      )}

      {open && (
        <div className="popup_overlay">
          <div className="popup_notify">
            <div className="popup_header">
              <h4>Add Book</h4>
              <button className="btn" onClick={openModal}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="popup_body">
              <form onSubmit={formik.handleSubmit}>
                <label htmlFor="courseId">Course</label>
                <select
                  name="courseId"
                  id="courseId"
                  className="input"
                  value={formik.values?.courseId}
                  onChange={(e) => {
                    console.log(e.target.value);
                    formik.setFieldValue("courseId", e.target.value);
                    handleFilterChange(e);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <option value="" disabled>
                    Select Course
                  </option>
                  {courses.map((e) => (
                    <option value={e.id} key={e.id}>
                      {e.name}
                    </option>
                  ))}
                </select>
                <small className="error">
                  {formik.touched.courseId && formik.errors.courseId}
                </small>

                <label htmlFor="course">Semester</label>
                <select
                  name="semesterId"
                  id="semesterId"
                  className="input"
                  value={formik.values?.semesterId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="" disabled>
                    Select semester
                  </option>
                  {semester.map((e) => (
                    <option value={e.id} key={e.id}>
                      {e.name}
                    </option>
                  ))}
                </select>
                <small className="error">
                  {formik.touched.semesterId && formik.errors.semesterId}
                </small>

                <label htmlFor="bookName">Book Name</label>
                <input
                  type="text"
                  id="bookName"
                  className="input"
                  name="bookName"
                  value={formik.values.bookName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.bookName && formik.errors.bookName}
                </small>

                <label htmlFor="priceKHI">Price KHI.</label>
                <input
                  type="number"
                  id="priceKhi"
                  className="input"
                  name="priceKhi"
                  value={formik.values.priceKhi}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.priceKhi && formik.errors.priceKhi}
                </small>

                <label htmlFor="priceRop">Price ROP</label>
                <input
                  type="number"
                  id="priceRop"
                  className="input"
                  name="priceRop"
                  value={formik.values.priceRop}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.priceRop && formik.errors.priceRop}
                </small>

                <label htmlFor="pages">Pages</label>
                <input
                  type="number"
                  id="pages"
                  className="input"
                  name="pages"
                  value={formik.values.pages}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.pages && formik.errors.pages}
                </small>

                {/* <label htmlFor="course">Region</label>
                <select
                  name="region"
                  id="region"
                  className="input"
                  value={formik.values?.region}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="" disabled>
                    Select Region
                  </option>
                  <option value="other">Other</option>
                  <option value="karachi">Karachi</option>
                </select>
                <small className="error">
                  {formik.touched.region && formik.errors.region}
                </small> */}

                <div className="popup_footer">
                  <button
                    className="btn btnclose"
                    onClick={openModal}
                    type="button"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btntheme">
                    {formik.values.id ? "Update" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}

      {open1 && (
        <div className="popup_overlay">
          <div
            className="popup_notify"
            style={{ width: "100%", height: "100%" }}
          >
            <div className="popup_header">
              <h4>Import Book</h4>
              <button className="btn" onClick={openModal1}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="popup_body">
              <ImportBooks setOpen={setOpen1} open={open1} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
