import Header from "./Components/header";
import { Sidebar } from "./Components/sidebar";
import React from "react";
import { Router } from "./Router";
import { useSelector } from "react-redux";
import { decryptData, getItem, Storage } from "./Services/Storage";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Axios } from "./Services/Axios";
import { CenterSettings } from "./Components/Settings/CenterSettings";
import { ToastContainer } from "react-toastify";
function App() {
  const auth = decryptData(useSelector((e) => e.LoggedIn));
  const { username, role } = Storage.getItem("user") || {};
  React.useEffect(() => {
    if (auth) {
      const token = getItem("token");
      Axios.interceptors.request.use(
        async (config) => {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token}`,
          };
          return config;
        },
        (err) => Promise.reject(err)
      );
    }
  }, [auth]);
  // React.useEffect(() => {
  //   console.log(role, "l");
  // }, [role]);
  return (
    <section className="dashboardLayout w-full h-full flex">
      {auth ? (
        <>
          <Header />
          <Sidebar />
        </>
      ) : (
        <></>
      )}
      <div className="mainContainer">
        {auth && (
          <div className="bg-[#222] flex justify-end p-2 ">
            <div className="mt-1">
              <span className="text-white block text-sm font-medium">
                {username}
              </span>
              <p className="text-white text-right text-xs">{role}</p>
            </div>
            <div className="flex items-center ml-2">
              <img
                src="https://beforeigosolutions.com/wp-content/uploads/2021/12/dummy-profile-pic-300x300-1.png"
                className="h-8 w-8 rounded-full"
              />
            </div>
          </div>
        )}
        <Router />
        {role?.role === "center" ? <CenterSettings /> : <></>}
      </div>
      <ToastContainer
        position="bottom-right"
        theme="colored"
        autoClose={3000}
      />
    </section>
  );
}

export default App;
