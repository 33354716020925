import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Axios } from "../../Services/Axios";
import { useFormik } from "formik";
import ReactPaginate from "react-paginate";
import { Storage } from "../../Services/Storage";
import { fetchPermissions } from "../../Components/sidebar";

const Index = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const pageCount = Math.ceil(formValues.length / 10);
  const [itemOffset, setItemOffset] = useState(0);
  const [filtered, setFiltered] = React.useState([]);
  const [courses, setCourses] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const id = Storage.getItem("user").id;

  const getSemester = () => {
    Axios.get(`/Semester/GetAll`).then((res) => {
      setLoading(false);
      setFormValues(res.data);
    });
  };

  const getCourses = () => {
    Axios.get(`/Course/GetAllDeleted`).then((res) => {
      let filteredData = res?.data.filter((e) => e.active === "Y");
      setCourses(filteredData);
    });
  };

  useState(() => {
    fetchPermissions(id)
      .then((res) => {
        const permission = res.find((item) => item.title == "Semester");
        // console.log(permission);
        setPermissions(permission);
      })
      .catch((err) => {
        toast.error("Uncaught error");
      });
    getCourses();
    getSemester();
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };

  const initialValues = {
    id: null,
    name: "",
    description: "",
    courseId: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required..."),
    courseId: Yup.string().required("This field is required..."),
  });
  const openModal = () => {
    if (open) {
      formik.resetForm();
    }
    setOpen(!open);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const payload = new FormData();
    const keys = Object.keys(values);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      payload.append(key, values[key]);
    }

    if (values.id) {
      Axios.put("/Semester/Update", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          resetForm();
          setOpen(!open);
          getSemester();
          toast.success("Data Updated successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    } else {
      payload.delete("id");
      Axios.post("/Semester/Add", payload, {
        headers: { "Content-Type": "multipart/form-data" },
      }).then(
        (res) => {
          resetForm();
          setOpen(!open);
          getSemester();
          toast.success("Data Updated successfully");
        },
        (err) => {
          toast.error("Something went wrong...");
        }
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleDelete = (id) => {
    Axios.delete(`/Semester/Remove?id=${id}`).then(
      (res) => {
        // getCourses();
        getSemester();
        toast.success("Data Delete successfully");
      },
      (err) => {
        toast.error("Something went wrong...");
      }
    );
  };

  const handleEdit = (obj) => {
    setOpen(!open);
    let updated = {
      id: obj?.id,
      name: obj?.name,
      description: obj?.description,
      courseId: obj?.courseId,
    };
    formik.setValues(updated);
  };

  return (
    <>
      <div className="pageTitle mx-4 my-4">
        <div>
          <h3>Semesters</h3>
        </div>
        {permissions.canAdd && (
          <button className="btn btntheme" onClick={openModal}>
            Add Semester
          </button>
        )}
      </div>

      <table id="customers">
        <tr>
          <th>Semester</th>
          <th>Course</th>
          <th>Description</th>
          {permissions.canUpdate || permissions.canDelete ? (
            <th>Action</th>
          ) : null}
        </tr>
        {loading && <p>Loading...</p>}
        {!loading && formValues.length === 0 && <p>No data found.</p>}
        {!loading && formValues.length > 0 && (
          <>
            {filtered.map((e, i) => (
              <tr>
                <td>{e.name}</td>
                <td>{e.course?.name}</td>
                <td>{e.description}</td>
                {permissions.canUpdate || permissions.canDelete ? (
                  <td>
                    {permissions.canDelete && (
                      <span onClick={() => handleDelete(e.id)}>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    )}
                    {permissions.canUpdate && (
                      <span onClick={() => handleEdit(e)} className="ml-5">
                        <i className="fa fa-pencil" aria-hidden="true"></i>
                      </span>
                    )}
                  </td>
                ) : null}
              </tr>
            ))}
          </>
        )}
      </table>
      {!loading && formValues.length > 0 && (
        <div>
          <ReactPaginate
            breakLabel="..."
            nextLabel="&raquo;"
            onPageChange={handlePage}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="&laquo;"
            renderOnZeroPageCount={null}
            containerClassName="mars-pagination"
            pageClassName="mars-page"
            nextClassName="mars-page"
            previousClassName="mars-page"
            activeClassName="active"
          />
        </div>
      )}

      {open && (
        <div className="popup_overlay">
          <div className="popup_notify">
            <div className="popup_header">
              <h4>Add Semester</h4>
              <button className="btn" onClick={openModal}>
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="popup_body">
              <form onSubmit={formik.handleSubmit}>
                <label htmlFor="courseId">Course</label>
                <select
                  name="courseId"
                  id="courseId"
                  className="input"
                  value={formik.values?.courseId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="" disabled>
                    Select Course
                  </option>
                  {courses.map((e) => (
                    <option value={e.id} key={e.id}>
                      {e.name}
                    </option>
                  ))}
                </select>
                <small className="error">
                  {formik.touched.courseId && formik.errors.courseId}
                </small>
                <label htmlFor="name">Semester Name</label>
                <input
                  type="text"
                  id="name"
                  className="input"
                  name="name"
                  placeholder="eg. CPISM"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.name && formik.errors.name}
                </small>

                <label htmlFor="description">Description</label>
                <input
                  type="text"
                  id="description"
                  className="input"
                  name="description"
                  placeholder="Optional"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="error">
                  {formik.touched.description && formik.errors.description}
                </small>
                <div className="popup_footer">
                  <button
                    className="btn btnclose"
                    onClick={openModal}
                    type="button"
                  >
                    Close
                  </button>
                  <button type="submit" className="btn btntheme">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
