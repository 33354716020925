import React, { useEffect, useState } from "react";
import { Axios, contentUrl } from "../../Services/Axios";

import { decryptData, getItem } from "../../Services/Storage";
import { useSelector } from "react-redux";

const Index = () => {
  const center = useSelector((e) => e.Center);
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const pageCount = Math.ceil(formValues.length / 10);
  const [itemOffset, setItemOffset] = useState(0);
  const [filtered, setFiltered] = React.useState([]);
  const [statuses, setStatuses] = React.useState({});
  const [checkedRows, setCheckedRows] = React.useState([]);
  const [loaderApprove, setLoaderApprove] = useState(false);
  const [loaderReject, setLoaderReject] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");
  const [csvloader, setCSVLoader] = useState(false);

  // Get current date
  const currentDate = new Date();
  // Format the current date to YYYY-MM format
  const currentMonth = `${currentDate.getFullYear()}-${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}`;

  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const [centers, setCenters] = useState([]);
  const [selectedcenters, setSelectedCenters] = useState(
    "00000000-0000-0000-0000-000000000000"
  );

  const getCenters = () => {
    Axios.get("/Centres/GetAll").then((res) => {
      setCenters(res.data);
    });
  };

  const getOrders = () => {
    // const selectedDate = new Date(selectedMonth);
    Axios.get(
      `/Order/GetByStatus?Status=${orderStatus}&startDate=${selectedMonth}&centerId${selectedcenters}`
    ).then((res) => {
      setLoading(false);
      setFormValues(res.data.reverse());
      setLoaderApprove(false);
      setLoaderReject(false);
    });
  };

  const getOrdersCenterWise = (id) => {
    Axios.get(`/Order/GetByCenter?CenterId=${id}`).then((res) => {
      setLoading(false);
      setFormValues(res.data?.reverse());
      setLoaderApprove(false);
      setLoaderReject(false);
    });
  };

  useEffect(() => {
    if (Boolean(center)) {
      getOrdersCenterWise(decryptData(center)?.id);
    } else {
      getCenters();
      // getOrders();
    }
  }, [center]);

  useEffect(() => {
    const endOffset = itemOffset + 10;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setFiltered(formValues.slice(itemOffset, endOffset));
  }, [itemOffset, formValues]);

  const handlePage = (event) => {
    const newOffset = (event.selected * 10) % formValues.length;
    setItemOffset(newOffset);
  };

  const handleChnage = (id, value) => {
    console.log(id);
    setStatuses((prevStatuses) => ({
      ...prevStatuses,
      [id]: value,
    }));

    Axios.put(`/Order/Update?Id=${id}&Status=${value}`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCheckboxChange = (id) => {
    // Toggle the checked state of the row with the given ID
    setCheckedRows((prevCheckedRows) => {
      if (prevCheckedRows.includes(id)) {
        return prevCheckedRows.filter((rowId) => rowId !== id);
      } else {
        return [...prevCheckedRows, id];
      }
    });
  };

  const handleApprove = () => {
    setLoaderApprove(true);
    // Send a request to update the status of checked rows to "approved"

    console.log(checkedRows);

    Axios.put(`/Order/UpdateRange?Status=approved`, checkedRows)
      .then((res) => {
        console.log(res);
        if (Boolean(center)) {
          getOrdersCenterWise(decryptData(center)?.id);
        } else {
          getOrders();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDistPatch = () => {
    setLoaderApprove(true);
    // Send a request to update the status of checked rows to "approved"

    console.log(checkedRows);

    Axios.put(`/Order/UpdateRange?Status=dispatch`, checkedRows)
      .then((res) => {
        console.log(res);
        if (Boolean(center)) {
          getOrdersCenterWise(decryptData(center)?.id);
        } else {
          getOrders();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    setLoaderApprove(true);
    // Send a request to update the status of checked rows to "approved"
    Axios.put(`/Order/UpdateRange?Status=closed`, checkedRows)
      .then((res) => {
        console.log(res);
        if (Boolean(center)) {
          getOrdersCenterWise(decryptData(center)?.id);
        } else {
          getOrders();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReject = () => {
    setLoaderReject(true);
    // Send a request to update the status of checked rows to "approved"
    Axios.put(`/Order/UpdateRange?Status=rejected`, checkedRows)
      .then((res) => {
        console.log(res);
        if (Boolean(center)) {
          getOrdersCenterWise(decryptData(center)?.id);
        } else {
          getOrders();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFilterChange = (e) => {
    setOrderStatus(e.target.value);
  };

  const handleFilterMethod = () => {
    setLoading(true);
    console.log(selectedcenters);
    Axios.get(
      `/Order/GetByStatus?Status=${orderStatus}&startDate=${selectedMonth}&centerId=${selectedcenters}`
    ).then((res) => {
      console.log(res.data);
      setLoading(false);
      setFormValues(res.data);
    });
  };

  const [expandedRow, setExpandedRow] = useState(null);

  const toggleRow = (index) => {
    if (expandedRow === index) {
      setExpandedRow(null);
    } else {
      setExpandedRow(index);
    }
  };

  const handleCenterChange = (e) => {
    setSelectedCenters(e.target.value);
  };

  const getUserDetailsByUserId = async (id) => {
    try {
      const res = await Axios.get(`/Users/GetById?Id=${id}`);
      return res.data.username;
    } catch (err) {
      console.log(err);
      return null; // Or handle the error as needed
    }
  };

  // const handleExport = async () => {
  //   // Function to format the date
  //   function formatDate(dateString) {
  //     const date = new Date(dateString);
  //     const options = { day: "2-digit", month: "short", year: "numeric" };
  //     return date.toLocaleDateString("en-GB", options); // Using British format for date
  //   }

  //   // Generate CSV rows from the data
  //   const rowsPromises = formValues.map(async (order) => {
  //     const username = await getUserDetailsByUserId(order.createdBy);
  //     return order.orderItems.map((item) =>
  //       [
  //         order.orderId,
  //         order.orderBy,
  //         username,
  //         item.bookname || "",
  //         item.course || "",
  //         item.semester || "",
  //         item.orderQTY,
  //         (item.unitPrice / 100).toFixed(2),
  //         (item.totalPrice / 100).toFixed(2),
  //         order.currentStatus,
  //         formatDate(order.createdDate),
  //       ].join(",")
  //     );
  //   });

  //   // Wait for all rows to be processed
  //   const rows = (await Promise.all(rowsPromises)).flat();

  //   // Define the CSV header
  //   const header =
  //     "OrderID,OrderBy,username,OrderTo,BookName,Course,Semester,Quantity,UnitPrice,TotalPrice,Status,Date";

  //   // Combine header and rows into CSV content
  //   const csvContent = [header, ...rows].join("\n");

  //   console.log(csvContent);
  // };

  const handleExport = () => {
    setCSVLoader(true);
    Axios.get(
      `/OrderItems/GetCsv?Status=${orderStatus}&CampusId=${selectedcenters}&StartDate=${selectedMonth}`
    ).then((res) => {
      // console.log(res.data);
      const blob = new Blob([res.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setCSVLoader(false);
    });
  };

  return (
    <>
      <div className="table mt-5">
        {checkedRows.length > 0 && (
          <>
            {orderStatus == "ordered" && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="btn btntheme ml-5 mb-5"
                  onClick={handleApprove}
                >
                  {loaderApprove ? "Please wait.." : "Approve"}
                </button>
                <button
                  className="btn btntheme ml-5 mb-5"
                  onClick={handleReject}
                >
                  {loaderReject ? "Please wait.." : "Reject"}
                </button>
              </div>
            )}

            {orderStatus == "printed" && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="btn btntheme ml-5 mb-5"
                  onClick={handleDistPatch}
                >
                  {loaderApprove ? "Please wait.." : "Dispatch"}
                </button>
              </div>
            )}

            {orderStatus == "dispatch" && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  className="btn btntheme ml-5 mb-5"
                  onClick={handleClose}
                >
                  {loaderApprove ? "Please wait.." : "Closed"}
                </button>
              </div>
            )}
          </>
        )}

        <div className="mb-5 mx-5" style={{ display: "flex" }}>
          <label htmlFor="filterSelect" className="filter-label">
            Filter by:
          </label>
          <select
            id="filterSelect"
            className="form-select"
            name="filter"
            onChange={handleCenterChange}
            style={{
              marginLeft: 10,
            }}
          >
            <option value="00000000-0000-0000-0000-000000000000">All</option>
            {centers.map((e) => (
              <option value={e?.id}>{e?.centreName}</option>
            ))}
          </select>
          <select
            id="filterSelect"
            className="form-select"
            name="filter"
            onChange={handleFilterChange}
            style={{
              marginLeft: 20,
            }}
          >
            <option value="">All</option>
            <option value="ordered">Ordered</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
            <option value="printing">Printing</option>
            <option value="printed">Printed</option>
            <option value="dispatch">Dispatch</option>
            <option value="closed">Closed</option>
          </select>

          <div className="mt-1 mr-5 w-40 ml-5">
            <input
              type="month"
              id="month"
              className="form-input"
              name="month"
              value={selectedMonth}
              onChange={(e) => {
                setSelectedMonth(e.target.value); // Adding 1 to get month number from 1 to 12
              }}
            />
          </div>

          <button className="ml-5 btn btntheme" onClick={handleFilterMethod}>
            {loading ? "Loading..." : "Order List"}
          </button>
        </div>
        {filtered.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              marginBottom: 10,
            }}
          >
            <button className="ml-5 btn btntheme" onClick={handleExport}>
              {csvloader ? "Exporting..." : "Export CSV"}
            </button>
          </div>
        )}

        <div
          className="flex tableheader"
          style={{ justifyContent: "space-between" }}
        >
          <div>#</div>
          <div>Order ID</div>
          {/* <div>Course Name</div> */}
          {/* <div>Semester Name</div> */}
          <div>Order By</div>
          <div>Books Quantity</div>
          <div>Total Price</div>

          <div>Status</div>
          <div>Order Date</div>
          <div>Action</div>
        </div>
        <div className="tablebody">
          {filtered.map((order, index) => (
            <React.Fragment key={order.id}>
              <div
                className="flex"
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <input
                    type="checkbox"
                    checked={checkedRows.includes(order.id)}
                    onChange={() => handleCheckboxChange(order.id)}
                  />
                </div>
                <div>
                  <span className="my-des-alt">{order.orderId}</span>
                </div>
                {/* <div>
                  <span className="my-des-alt">{order.course}</span>
                </div> */}
                {/* <div>
                  <span className="my-des-alt">{order.semester}</span>
                </div> */}
                <div>
                  <span className="my-des-alt">{order.orderBy}</span>
                </div>
                <div>
                  <span className="my-des-alt">{order.totalCount}</span>
                </div>
                <div>
                  <span className="my-des-alt">{order.totalPrice}</span>
                </div>

                <div>
                  <span className="my-des-alt">
                    {order.currentStatus?.toUpperCase()}
                  </span>
                </div>
                <div>
                  <span className="my-des-alt">
                    {order?.createdDate?.split("T")[0]}
                  </span>
                </div>
                <div>
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => toggleRow(index)}
                  >
                    <i className="fa fa-eye" aria-hidden="true"></i>
                  </span>
                  <span style={{ marginLeft: 10 }}>
                    <a
                      href={contentUrl + order?.paymentSlip}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="fa-solid fa-receipt"></i>
                    </a>
                  </span>
                </div>
              </div>
              {expandedRow === index && (
                <>
                  <table border={1} style={{ width: "100%" }}>
                    <thead>
                      <th>book name</th>
                      <th>course</th>
                      <th>semester</th>
                      <th>quantity</th>
                      <th>unit price</th>
                      <th>total price</th>
                    </thead>
                    <tbody>
                      {order?.orderItems.map((e) => (
                        <tr style={{ textAlign: "center" }}>
                          <td>{e?.bookname}</td>
                          <td>{e?.course}</td>
                          <td>{e?.semester}</td>
                          <td>{e?.orderQTY}</td>
                          <td>{e?.unitPrice}</td>
                          <td>{e?.totalPrice}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </>
              )}
            </React.Fragment>
          ))}

          {/* {loading && <p>Loading...</p>}
          {!loading && formValues.length === 0 && <p>No data found.</p>}
          {!loading && formValues.length > 0 && (
            <>
              {filtered.map((e, i) => (
                <div
                  className="flex"
                  key={i}
                  style={{ width: "100%", justifyContent: "space-between" }}
                >
                  <input
                    type="checkbox"
                    checked={checkedRows.includes(e.id)}
                    onChange={() => handleCheckboxChange(e.id)}
                  />
                  <div>
                    <span className="my-des-alt">{e.bookname}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.course}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.semester}</span>
                  </div>
                  <div>
                    <span className="my-des-alt">{e.orderBy}</span>
                  </div>

                  <div>
                    <span className="my-des-alt">{e.orderQTY}</span>
                  </div>

                  <div>
                    <span className="my-des-alt">{e.unitPrice}</span>
                  </div>

                  <div>
                    <span className="my-des-alt">{e.totalPrice}</span>
                  </div>

                 

                  <div>
                    <span className="my-des-alt">{e.currentStatus}</span>
                  </div>

                  <div>
                   
                  </div>
                </div>
              ))}
              <ReactPaginate
                breakLabel="..."
                nextLabel="&raquo;"
                onPageChange={handlePage}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel="&laquo;"
                renderOnZeroPageCount={null}
                containerClassName="mars-pagination"
                pageClassName="mars-page"
                nextClassName="mars-page"
                previousClassName="mars-page"
                activeClassName="active"
              />
            </>
          )} */}
        </div>
      </div>
    </>
  );
};

export default Index;
