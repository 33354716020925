import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import {
  clear,
  decryptData,
  encryptData,
  removeItem,
  setItem,
  Storage,
} from "../Services/Storage";
import { Axios } from "../Services/Axios";
import { toast } from "react-toastify";

export const adminModules = [
  { name: "Cities", link: "/Cities" },
  { name: "Centers", link: "/centers" },
  { name: "Course Inquiry", link: "/course-inquiry" },
  { name: "Placements", link: "/placements" },
  { name: "POM", link: "/Pom" },
  { name: "SOM", link: "/Som" },
  { name: "Upcoming Batches", link: "/upcoming-batches" },
  { name: "Events", link: "/events" },
  { name: "Tickets", link: "/tickets" },
  { name: "Issues", link: "/issues" },
  { name: "Statuses", link: "/statuses" },
  { name: "Courses", link: "/courses" },
  { name: "Semester", link: "/semester" },
  { name: "Books", link: "/oms" },
  { name: "Orders", link: "/orders" },
  { name: "Settings", link: "/Settings" },
];

export const userModules = [
  { name: "Course Inquiry", link: "/course-inquiry" },
  { name: "Placements", link: "/placements" },
  { name: "POM", link: "/Pom" },
  { name: "SOM", link: "/Som" },
  { name: "Upcoming Batches", link: "/upcoming-batches" },
  { name: "Events", link: "/events" },
  { name: "Tickets", link: "/tickets" },
  { name: "ECM order", link: "/oms-center" },
  { name: "Ordered", link: "/orders" },
  { name: "Settings", link: "/Settings" },
];

export const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [centers, setCenters] = React.useState([]);
  const [userPermissions, setUserPermissions] = React.useState(
    Storage.getItem("user").permissions
  );
  const [cc, SetCC] = React.useState("");
  const [center, setCenter] = React.useState(null);

  const [init, setInit] = React.useState(true);
  const xt = useSelector((e) => e.Center);
  const role = decryptData(useSelector((e) => e.Role));
  const id = Storage.getItem("user").id;

  const Logout = () => {
    clear();
    dispatch({ type: "setLogin", payload: encryptData(false) });
    dispatch({ type: "setUser", payload: null });
    dispatch({ type: "setRole", payload: null });
    dispatch({ type: "setCenter", payload: null });
    dispatch({ type: "setOpenSettings", payload: null });

    navigate("/login");
  };
  const openSettings = (val) => {
    dispatch({ type: "setOpenSettings", payload: encryptData(val) });
  };
  const links = [
    { to: "/", text: "Dashboard" },
    { to: "/inquiry", text: "Course Inquiry" },
    { to: "/centers", text: "Centers" },
    // { to: "/batches", text: "Upcoming Batches" },
    // { to: "/som", text: "Student of the Month" },
    // { to: "/pom", text: "Project of the Month" },
    // { to: "/placement", text: "Placements" },
    // { to: "/events", text: "Center Events" },
    // { to: "/survey", text: "Survey" },
    // { to: "/complaint", text: "Complain" },
  ];

  React.useEffect(() => {
    if (center) {
      SetCC(center.id);
    }
    if (role.role === "admin") {
      GetCenters();
    }
  }, [center]);

  React.useEffect(() => {
    if (xt) {
      setCenter(decryptData(xt));
    }
  }, [xt]);
  React.useEffect(() => {
    const nc = centers.find((x) => x.id === cc);
    if (nc) {
      dispatch({ type: "setCenter", payload: encryptData(nc) });
      setItem("center", nc);
    } else if (cc === "admin") {
      dispatch({ type: "setCenter", payload: null });
      removeItem("center");
    }
  }, [cc]);

  React.useEffect(() => {
    fetchPermissions(id)
      .then((res) => {
        setUserPermissions(res);
      })
      .catch((err) => toast.error("Error"));
  }, []);

  const GetCenters = () => {
    Axios.get("/Centres/GetAll").then((res) => {
      setCenters(res.data);
    });
  };
  return (
    <div className="sideBar border-r-2 border-r-white ">
      <div className="sideBarHeader">
        <h4 className="m-0">Aptech Dashboard</h4>
      </div>
      <div className="sideBarBody">
        <div className="sideBarContainer">
          {/* <NavLink to="/" className="sideLink" activeclassname="active">
            Dashboard
          </NavLink> */}
          {/* {role.role === "admin" ? (
            <>
              <NavLink
                to="/cities"
                className="sideLink"
                activeclassname="active"
              >
                Cities
              </NavLink>
              <NavLink
                to="/centers"
                className="sideLink"
                activeclassname="active"
              >
                Centers
              </NavLink>

              <NavLink
                to="/course-inquiry"
                className="sideLink"
                activeclassname="active"
                onClick={() => openSettings(false)}
              >
                Course Inquiry
              </NavLink>
              <NavLink
                to="/placements"
                className="sideLink"
                activeclassname="active"
                onClick={() => openSettings(false)}
              >
                Placements
              </NavLink>
              <NavLink
                to="/Pom"
                className="sideLink"
                activeclassname="active"
                onClick={() => openSettings(false)}
              >
                POM
              </NavLink>
              <NavLink
                to="/Som"
                className="sideLink"
                activeclassname="active"
                onClick={() => openSettings(false)}
              >
                SOM
              </NavLink>
              <NavLink
                to="/upcoming-batches"
                className="sideLink"
                activeclassname="active"
                onClick={() => openSettings(false)}
              >
                Upcoming Batches
              </NavLink>
              <NavLink
                to="/events"
                className="sideLink"
                activeclassname="active"
                onClick={() => openSettings(false)}
              >
                Events
              </NavLink>
              <NavLink
                to="/tickets"
                className="sideLink"
                activeclassname="active"
                onClick={() => openSettings(false)}
              >
                Tickets
              </NavLink>
              <NavLink
                to="/issues"
                className="sideLink"
                activeclassname="active"
                onClick={() => openSettings(false)}
              >
                Issues
              </NavLink>
              <NavLink
                to="/statuses"
                className="sideLink"
                activeclassname="active"
                onClick={() => openSettings(false)}
              >
                Statuses
              </NavLink>

              <div style={{ padding: "15px 8px" }}>
                <label
                  style={{
                    color: "#fff",
                    fontSize: "16px",
                    paddingLeft: "5px",
                  }}
                >
                  Filter By Center
                </label>
                <select
                  className="mars-input"
                  value={cc}
                  onChange={(e) => SetCC(e.target.value)}
                >
                  <option value="admin">All</option>
                  {centers?.map((e) => (
                    <option value={e.id} key={e.id}>
                      {e.centreName}
                    </option>
                  ))}
                </select>
              </div>
              <NavLink
                to="/courses"
                className="sideLink"
                activeclassname="active"
              >
                Course
              </NavLink>
              <NavLink
                to="/semester"
                className="sideLink"
                activeclassname="active"
              >
                Semester
              </NavLink>
              <NavLink to="/oms" className="sideLink" activeclassname="active">
                Books
              </NavLink>
              <NavLink
                to="/orders"
                className="sideLink"
                activeclassname="active"
              >
                Orders
              </NavLink>
              <NavLink
                to="/Settings"
                className="sideLink"
                activeclassname="active"
              >
                Settings
              </NavLink>
            </>
          ) : (
            <></>
          )} */}
          <hr />
          {/* {role.role === "center" ? (
            <>
              add cc == 'guid centerid for specific condition otherwise false'
              {false ? (
                <>
                  <NavLink
                    to="/oms-center"
                    className="sideLink"
                    activeclassname="active"
                  >
                    ECM Order
                  </NavLink>
                  <NavLink
                    to="/orders"
                    className="sideLink"
                    activeclassname="active"
                  >
                    Ordered
                  </NavLink>
                </>
              ) : (
                <>
                  <NavLink
                    to="/course-inquiry"
                    className="sideLink"
                    activeclassname="active"
                    onClick={() => openSettings(false)}
                  >
                    Course Inquiry
                  </NavLink>
                  <NavLink
                    to="/placements"
                    className="sideLink"
                    activeclassname="active"
                    onClick={() => openSettings(false)}
                  >
                    Placements
                  </NavLink>
                  <NavLink
                    to="/Pom"
                    className="sideLink"
                    activeclassname="active"
                    onClick={() => openSettings(false)}
                  >
                    POM
                  </NavLink>
                  <NavLink
                    to="/Som"
                    className="sideLink"
                    activeclassname="active"
                    onClick={() => openSettings(false)}
                  >
                    SOM
                  </NavLink>
                  <NavLink
                    to="/upcoming-batches"
                    className="sideLink"
                    activeclassname="active"
                    onClick={() => openSettings(false)}
                  >
                    Upcoming Batches
                  </NavLink>
                  <NavLink
                    to="/events"
                    className="sideLink"
                    activeclassname="active"
                    onClick={() => openSettings(false)}
                  >
                    Events
                  </NavLink>
                  <NavLink
                    to="/tickets"
                    className="sideLink"
                    activeclassname="active"
                    onClick={() => openSettings(false)}
                  >
                    Tickets
                  </NavLink>
                  <NavLink
                    to="/oms-center"
                    className="sideLink"
                    activeclassname="active"
                  >
                    ECM Order
                  </NavLink>
                  <NavLink
                    to="/orders"
                    className="sideLink"
                    activeclassname="active"
                  >
                    Ordered
                  </NavLink>
                  <NavLink
                    to="/settings"
                    className="sideLink"
                    activeclassname="active"
                  >
                    Settings
                  </NavLink>
                </>
              )}
            </>
          ) : (
            <></>
          )} */}
          {/* {role?.role === "OMS" && (
            <>
              <NavLink
                to="/orders"
                className="sideLink"
                activeclassname="active"
              >
                Orders
              </NavLink>
            </>
          )}
          {role.role === "employee" && (
            <>
              {console.log(userPermissions)}
              {userPermissions.map((item, i) =>
                item.canView ? (
                  <NavLink
                    to={`${item.title === "Books"
                      ? "oms"
                      : item.title.replace(/ /g, "-").toLowerCase()
                      }`}
                    className="sideLink"
                    activeclassname="active" // Note the corrected spelling of activeClassName
                    key={i}
                  >
                    {console.log(item)}
                    {item.title}
                  </NavLink>
                ) : null
              )}
            </>
          )} */}

          {role.role == "admin"
            ? userPermissions.map((item, i) =>
                item.canView ? (
                  <NavLink
                    to={`${
                      item.title === "Books"
                        ? "oms"
                        : item.title.replace(/ /g, "-").toLowerCase()
                    }`}
                    className="sideLink"
                    activeclassname="active" // Note the corrected spelling of activeClassName
                    key={i}
                  >
                    {item.title}
                  </NavLink>
                ) : null
              )
            : userPermissions.map((item, i) =>
                item.canView ? (
                  <NavLink
                    to={`${
                      item.title === "ECM order"
                        ? "oms-center"
                        : item.title === "Ordered"
                        ? "orders"
                        : item.title.replace(/ /g, "-").toLowerCase()
                    }`}
                    className="sideLink"
                    activeclassname="active" // Note the corrected spelling of activeClassName
                    key={i}
                  >
                    {item.title}
                  </NavLink>
                ) : null
              )}

          {/* <>
            {role.role === "center"
              ? user.map((item, i) => (
                  <NavLink
                    to={`${item.link}`}
                    className="sideLink"
                    activeclassname="active" // Note the corrected spelling of activeClassName
                    key={i}
                  >
                    {item.name}
                  </NavLink>
                ))
              : userPermissions.map((item, i) =>
                  item.canView ? (
                    <NavLink
                      to={`${
                        item.title === "Books"
                          ? "oms"
                          : item.title.replace(/ /g, "-").toLowerCase()
                      }`}
                      className="sideLink"
                      activeclassname="active" // Note the corrected spelling of activeClassName
                      key={i}
                    >
                      {item.title}
                    </NavLink>
                  ) : null
                )}
          </> */}
        </div>
      </div>
      <div className="sideBarFooter">
        <button className="sideLink" onClick={Logout}>
          Log out
        </button>
      </div>
    </div>
  );
};

export const fetchPermissions = (id) => {
  return Axios.get(`/Permissions/GetByUserId?Id=${id}`)
    .then((response) => response.data)
    .catch((error) => {
      toast.error("Error fetching permissions");
      throw error; // Re-throw the error to allow further handling if needed
    });
};
