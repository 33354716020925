import { Formik } from 'formik';
import React from 'react'

const ManagePermissions = ({ initVal, btnTxt, permissionsLoading, setOpen, userId, open, onSubmit, modules }) => {

    return (
        <Formik
            initialValues={initVal}
            enableReinitialize
            onSubmit={onSubmit}
        >
            {(formik) => (
                <form>
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                    Modules
                                </th>
                                <th
                                    scope="col"
                                    colSpan="4" // Ensures the Permissions header spans across all columns
                                    className="px-7 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell"
                                >
                                    Permissions
                                </th>
                            </tr>
                        </thead>
                        {permissionsLoading && (
                            <tbody>
                                <tr>
                                    <td className="text-base p-4">
                                        Loading...
                                    </td>
                                </tr>
                            </tbody>
                        )}

                        {!permissionsLoading && (
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {modules.map(({ name }, i) => (
                                    <tr key={i}>

                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">

                                            <div className="flex place-items-center">
                                                <input
                                                    id={`${name}`}
                                                    aria-describedby="comments-description"
                                                    name={`${name}`}
                                                    value={name}
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-2"
                                                    onChange={() => {
                                                        formik.setFieldValue(
                                                            `${name}`,
                                                            !formik.values[`${name}`]
                                                        );

                                                        formik.setFieldValue(
                                                            `view${name}`,
                                                            !formik.values[`${name}`]
                                                        );

                                                        formik.setFieldValue(
                                                            `add${name}`,
                                                            !formik.values[`${name}`]
                                                        );

                                                        formik.setFieldValue(
                                                            `update${name}`,
                                                            !formik.values[`${name}`]
                                                        );

                                                        formik.setFieldValue(
                                                            `delete${name}`,
                                                            !formik.values[`${name}`]
                                                        );
                                                    }}
                                                    checked={formik.values[`${name}`]}
                                                />
                                                <label htmlFor={`${name}`}>
                                                    {name}
                                                </label>
                                            </div>
                                        </td>
                                        <td className="px-7 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell">
                                            <div className="flex place-items-center">
                                                <input
                                                    id={`view${name}`}
                                                    aria-describedby="comments-description"
                                                    name={`view${name}`}
                                                    value={`view${name}`}
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-2"
                                                    onChange={() => {
                                                        if (
                                                            !formik.values[`view${name}`] &&
                                                            !formik.values[`${name}`]
                                                        ) {
                                                            formik.setFieldValue(
                                                                `${name}`,
                                                                !formik.values[`${name}`]
                                                            );

                                                            formik.setFieldValue(
                                                                `view${name}`,
                                                                !formik.values[`view${name}`]
                                                            );
                                                        } else {
                                                            formik.setFieldValue(
                                                                `view${name}`,
                                                                !formik.values[`view${name}`]
                                                            );
                                                        }
                                                    }}
                                                    checked={
                                                        formik.values[`view${name}`]
                                                    }
                                                />

                                                <label htmlFor={`view${name}`}>
                                                    View
                                                </label>
                                            </div>
                                        </td>
                                        <td className="px-7 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell">
                                            <div className="flex place-items-center">
                                                <input
                                                    id={`add${name}`}
                                                    aria-describedby="comments-description"
                                                    name={`add${name}`}
                                                    value={`add${name}`}
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-2"
                                                    onChange={(e) => {
                                                        if (
                                                            !formik.values[`add${name}`] &&
                                                            !formik.values[`${name}`]
                                                        ) {
                                                            formik.setFieldValue(
                                                                `${name}`,
                                                                !formik.values[`${name}`]
                                                            );

                                                            formik.setFieldValue(
                                                                `add${name}`,
                                                                !formik.values[`add${name}`]
                                                            );
                                                        } else {
                                                            formik.setFieldValue(
                                                                `add${name}`,
                                                                !formik.values[`add${name}`]
                                                            );
                                                        }
                                                    }}
                                                    checked={
                                                        formik.values[`add${name}`]
                                                    }
                                                />
                                                <label htmlFor={`add${name}`}>
                                                    Create
                                                </label>
                                            </div>
                                        </td>
                                        <td className="px-7 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell">
                                            <div className="flex place-items-center">
                                                <input
                                                    id={`update${name}`}
                                                    value={`update${name}`}
                                                    aria-describedby="comments-description"
                                                    name={`update${name}`}
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-2"
                                                    onChange={(e) => {
                                                        if (
                                                            !formik.values[
                                                            `update${name}`
                                                            ] &&
                                                            !formik.values[`${name}`]
                                                        ) {
                                                            formik.setFieldValue(
                                                                `${name}`,
                                                                !formik.values[`${name}`]
                                                            );

                                                            formik.setFieldValue(
                                                                `update${name}`,
                                                                !formik.values[
                                                                `update${name}`
                                                                ]
                                                            );
                                                        } else {
                                                            formik.setFieldValue(
                                                                `update${name}`,
                                                                !formik.values[
                                                                `update${name}`
                                                                ]
                                                            );
                                                        }
                                                    }}
                                                    checked={
                                                        formik.values[`update${name}`]
                                                    }
                                                />
                                                <label htmlFor={`update${name}`}>
                                                    Update
                                                </label>
                                            </div>
                                        </td>
                                        <td className="px-7 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell">
                                            <div className="flex place-items-center">
                                                <input
                                                    id={`delete${name}`}
                                                    value={`delete${name}`}
                                                    aria-describedby="comments-description"
                                                    name={`delete${name}`}
                                                    type="checkbox"
                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 mr-2"
                                                    onChange={(e) => {
                                                        if (
                                                            !formik.values[
                                                            `delete${name}`
                                                            ] &&
                                                            !formik.values[`${name}`]
                                                        ) {
                                                            formik.setFieldValue(
                                                                `${name}`,
                                                                !formik.values[`${name}`]
                                                            );

                                                            formik.setFieldValue(
                                                                `delete${name}`,
                                                                !formik.values[
                                                                `delete${name}`
                                                                ]
                                                            );
                                                        } else {
                                                            formik.setFieldValue(
                                                                `delete${name}`,
                                                                !formik.values[
                                                                `delete${name}`
                                                                ]
                                                            );
                                                        }
                                                    }}
                                                    checked={
                                                        formik.values[`delete${name}`]
                                                    }
                                                />
                                                <label htmlFor={`delete${name}`}>
                                                    Delete
                                                </label>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                    <div
                        className={`error-message transition-opacity duration-500 ${formik.errors.error
                            ? "opacity-100 h-auto"
                            : "opacity-0 h-0"
                            }`}
                    >
                        <span className="text-red-500 text-sm sm:pl-6 block">
                            {formik.errors.error}
                        </span>
                    </div>

                    {!permissionsLoading && (
                        <button
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`mt-4 p-2 px-4 bg-yellow-300 text-white rounded sm:ml-6 text-base ${formik.isSubmitting ? "opacity-70 " : ""
                                }`}
                            onClick={formik.handleSubmit}
                        >
                            {formik.isSubmitting ? (
                                <div className="flex place-items-center">
                                    <svg
                                        className="animate-spin -ml-1 mr-3 size-3 text-white"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                    >
                                        <circle
                                            className="opacity-25"
                                            cx="12"
                                            cy="12"
                                            r="10"
                                            stroke="currentColor"
                                            strokeWidth="4"
                                        ></circle>
                                        <path
                                            className="opacity-75"
                                            fill="currentColor"
                                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                        ></path>
                                    </svg>
                                    {btnTxt}ing...
                                </div>
                            ) : (
                                btnTxt
                            )}
                        </button>
                    )}
                </form>
            )}
        </Formik>
    )
}

export default ManagePermissions