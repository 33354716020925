import React from "react";
import { decryptData, Storage } from "../../Services/Storage";
import { Axios, baseUrl } from "../../Services/Axios";
import { toast } from "react-toastify";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import {
  XMarkIcon,
  ExclamationTriangleIcon,
  ArrowUturnLeftIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import Modal from "../../Components/Modal";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import Loader from "../../Components/loader";
import { useSelector } from "react-redux";
import { fetchPermissions } from "../../Components/sidebar";
import SelectField from "../../Components/SelectField";

function PaperclipIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m21.44 11.05-9.19 9.19a6 6 0 0 1-8.49-8.49l8.57-8.57A4 4 0 1 1 18 8.84l-8.59 8.57a2 2 0 0 1-2.83-2.83l8.49-8.48" />
    </svg>
  );
}

function ArrowLeftIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m12 19-7-7 7-7" />
      <path d="M19 12H5" />
    </svg>
  );
}

export default function Index() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [deleteloading, setDeleteLoading] = React.useState(false);
  const [replyModal, setReplyModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [permissions, setPermissions] = React.useState([]);
  const [tickets, setTickets] = React.useState([]);
  const [allTickets, setAllTickets] = React.useState([]);
  const [issues, setIssues] = React.useState([]);
  const [conversation, setConversation] = React.useState([]);
  const [specificConversation, setSpecificConversation] = React.useState([]);
  const [replyItem, setReplyItem] = React.useState([]);
  const [statuses, setStatuses] = React.useState([]);
  const [localFiles, setLocalFiles] = React.useState([]);
  const [allCenters, setAllCenters] = React.useState([]);
  const [selectStatus, setSelectStatus] = React.useState({
    name: "Pending",
  });
  const [selectCenter, setSelectCenter] = React.useState({
    centreName: "All Centers",
  });
  const [deleteId, setDeleteId] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [centerId, setCenterId] = React.useState("");
  const [selected, setSelected] = React.useState("");
  const { id, centresId, role } = Storage.getItem("user");

  const getAllStatuses = () => {
    Axios.get("/Status/GetAll")
      .then((res) => {
        setStatuses(res.data);
      })
      .catch((err) => toast.error(err));
  };

  const getAllConversation = (id) => {
    if (role == "admin") {
      return Axios.get("/Conversation/GetAllWithRelationShip")
        .then((res) => {
          setConversation(res.data);
          setLoading(false);
          return res.data;
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
          return Promise.reject(err);
        });
    } else if (role == "center" || role == "employee") {
      return Axios.get(`/Conversation/GetBYCustomerId?Id=${id ? id : centerId}`)
        .then((res) => {
          setConversation(res.data);
          setLoading(false);
          return res.data;
        })
        .catch((err) => {
          toast.error(err);
          setLoading(false);
          return Promise.reject(err);
        });
    }
  };

  const getIssues = () => {
    Axios.get("/IssueType/GetAll")
      .then((res) => {
        setIssues(res.data);
      })
      .catch((err) => toast.error(err));
  };

  const deleteStatus = () => {
    setDeleteLoading(true);
    Axios.delete(`Status/Remove?id=${deleteId}`)
      .then((res) => {
        getAllTickets();
        setDeleteModal(false);
        setDeleteLoading(false);
      })
      .catch((err) => {
        setDeleteLoading(false);
        toast.error(err);
      });
  };

  const handleImageInput = function (e) {
    // Prevent default form submission behavior
    e.preventDefault();

    // Get the files from the input
    const files = Array.from(e.target.files);

    // Define allowed file extensions
    const allowedExtensions = [
      "pdf",
      "jpg",
      "jpeg",
      "xlsx",
      "xls",
      "png",
      "csv",
    ];

    // Filter files based on allowed extensions
    const filteredFiles = files.filter((file) => {
      const fileName = file.name;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot).toLowerCase();

      return allowedExtensions.includes(extFile);
    });

    // Handle invalid files
    const invalidFiles = files.filter((file) => {
      const fileName = file.name;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot).toLowerCase();
      return !allowedExtensions.includes(extFile);
    });

    if (invalidFiles.length > 0) {
      alert("Only PDF, JPG, JPEG, XLS, and XLSX files are allowed!");
    }

    // Update the state with the valid files
    const updatedFiles = [...localFiles, ...filteredFiles];
    setLocalFiles(updatedFiles);
  };

  const GetCenters = () => {
    Axios.get("/Centres/GetAll").then((res) => {
      setAllCenters([{ id: "abc", centreName: "All Centers" }, ...res.data]);
    });
  };

  React.useEffect(() => {
    setUserId(id);
    setCenterId(centresId);

    fetchPermissions(id)
      .then((res) => {
        const permission = res.find((item) => item.title == "Tickets");
        // console.log(permission);
        setPermissions(permission);
      })
      .catch((err) => {
        toast.error("Uncaught error");
      });
    getIssues();

    getAllStatuses();
    GetCenters();
  }, []);

  const getAllTickets = async () => {
    setLoading(true);
    try {
      const fetchTickets =
        role === "center" || role === "employee"
          ? await Axios.get(`/Ticket/GetBYCustomerId?Id=${centresId}`)
          : await Axios.get("/Ticket/GetAllWithRelationShip");

      const ticketsRes = fetchTickets.data;
      const convoRes = await getAllConversation(centresId);

      if (Array.isArray(ticketsRes) && Array.isArray(convoRes)) {
        ticketsRes.forEach((ticket) => {
          const specificConvo = convoRes.filter(
            (convo) => ticket.id === convo.ticketId
          );
          if (specificConvo.length) {
            ticket.lastUpdateBy = specificConvo.at(-1);
          }
        });

        setAllTickets(ticketsRes.reverse());
        updateTickets(ticketsRes);
      } else {
        console.error("Invalid data format");
      }
    } catch (err) {
      toast.error(err.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  const updateTickets = (tickets) => {
    let filteredTickets = tickets;

    if (selectCenter.centreName !== "All Centers") {
      filteredTickets = filteredTickets.filter(
        (ticket) => ticket.centresDetail.centreName === selectCenter.centreName
      );
    }

    if (selectStatus.name.toLowerCase() !== "all tickets") {
      filteredTickets = filteredTickets.filter((ticket) => {
        const ticketStatus = ticket.ticketStatus
          ? ticket.ticketStatus.status
          : ticket.status;
        return ticketStatus === selectStatus.name;
      });
    }

    if (
      selectStatus.name.toLowerCase() === "all tickets" &&
      selectCenter.centreName === "All Centers"
    ) {
      filteredTickets = allTickets;
    }

    console.log(filteredTickets);

    setTickets(filteredTickets);
  };

  React.useEffect(() => {
    if (
      selectStatus.name.toLowerCase() === "all tickets" &&
      selectCenter.centreName === "All Centers"
    ) {
      updateTickets(allTickets);
    }
    if (allTickets.length === 0) {
      getAllTickets();
    } else {
      updateTickets(allTickets);
    }
  }, [selectCenter, selectStatus]);

  React.useEffect(() => {
    // console.log(tickets);
  }, [tickets]);

  return (
    <>
      {!replyModal && (
        <div className="px-4 sm:px-6 lg:px-8 my-4">
          <div className="flex justify-between">
            <div className="">
              <h1 className="text-xl font-semibold text-gray-900">Tickets</h1>
              <p className="mt-2 text-sm text-gray-700">
                Here are the current tickets
              </p>
            </div>
            <div className="flex">
              {role == "admin" && (
                <div className=" min-w-72">
                  {/* {console.log(allCenters)} */}
                  <SelectField
                    select={selectCenter}
                    setSelect={setSelectCenter}
                    array={allCenters}
                    arrayKey={"centreName"}
                    label={"Filter By Center"}
                  />
                </div>
              )}
              <div className="mx-2 min-w-72">
                <SelectField
                  select={selectStatus}
                  setSelect={setSelectStatus}
                  array={[{ name: "All Tickets" }, ...statuses]}
                  arrayKey={"name"}
                  label={"Filter By Status"}
                />
              </div>
              {permissions.canAdd && role !== "admin" && (
                <div className="">
                  <div className="h-5"></div>
                  <button
                    type="button"
                    className="btn btntheme"
                    onClick={() => setOpen(!open)}
                  >
                    Add Ticket
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Description
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Start Date
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Center Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Priority
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Last Updated By
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {loading && (
                        <tr>
                          <td className="text-base p-4">Loading...</td>
                        </tr>
                      )}
                      {tickets.length <= 0 && !loading && (
                        <tr>
                          <td className="text-base p-4 text-red-500">
                            No tickets available
                          </td>
                        </tr>
                      )}
                      {!loading &&
                        tickets.map((status, i) => (
                          <tr key={i}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {status.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 overflow-hidden overflow-ellipsis max-w-xs">
                              {status.subject}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 overflow-hidden overflow-ellipsis max-w-xs">
                              {new Date(
                                status.createdDate
                              ).toLocaleDateString()}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {status.centresDetail.centreName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm ">
                              <span
                                className={classNames(
                                  "px-2 py-1 text-xs font-semibold rounded",
                                  status.priority === "High"
                                    ? "bg-red-200 text-red-800"
                                    : status.priority === "Medium"
                                    ? "bg-green-200 text-green-800"
                                    : "bg-yellow-200 text-yellow-800"
                                )}
                              >
                                {status.priority}
                              </span>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm ">
                              {status.ticketStatus == null
                                ? status.status
                                : status.ticketStatus.status}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm">
                              {status.lastUpdateBy ? (
                                <>
                                  {status.lastUpdateBy.createdByName}
                                  <span className="text-[12px]">
                                    -({status.lastUpdateBy.roleName})
                                  </span>
                                </>
                              ) : (
                                <>
                                  {status.createdByName}
                                  <span className="text-[12px]">
                                    -({status.roleName})
                                  </span>
                                </>
                              )}
                            </td>

                            <td className="whitespace-nowrap px-3 py-4 text-sm flex">
                              <span
                                onClick={() => {
                                  const convo = conversation.filter(
                                    (msg) => msg.ticketId == status.id
                                  );

                                  setSpecificConversation(convo);
                                  const priority = statuses.filter(
                                    (item) => item.name == status.status
                                  );
                                  if (!status.ticketStatus) {
                                    setSelected(priority[0]);
                                  } else {
                                    setSelected(status.ticketStatus);
                                  }
                                  setReplyItem(status);
                                  setReplyModal(true);
                                }}
                                className={classNames(
                                  "px-3 py-1 text-xs font-semibold rounded bg-blue-400 text-white flex place-items-center space-y-2 cursor-pointer"
                                )}
                              >
                                <ArrowUturnLeftIcon className="size-5 mr-2" />
                                See more
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        open={open}
        setOpen={setOpen}
        width={"max-w-4xl"}
        content={
          <div className="max-w-7xl mx-auto p-6 bg-white rounded-md">
            <div className="flex items-center mb-6">
              <ArrowLeftIcon className="h-5 w-5 mr-2" />
              <h2 className="text-xl font-semibold">Add Task</h2>
            </div>
            <Formik
              initialValues={{
                priority: "",
                issues: "",
                name: "",
                subject: "",
              }}
              validationSchema={yup.object().shape({
                priority: yup.string().required("Priority is required"),
                issues: yup.string().required("Issues is required"),
                name: yup.string().required("Name is required"),
                subject: yup.string().required("Description is required"),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                values.issues = JSON.parse(values.issues);
                const transformedObject = {
                  priority: values.priority,
                  name: values.name,
                  subject: values.subject,
                  issueType: values.issues.name,
                  issueTypeTag: values.issues.tag,
                  issueTypeId: values.issues.id,
                  usersId: userId,
                  centresId: centerId,
                  status: "Pending",
                };

                let date = new Date();
                transformedObject.ticketNumber = `${date.getFullYear()}${
                  date.getMonth() + 1
                }${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`;

                Axios.post("/Ticket/Add", transformedObject)
                  .then((res) => {
                    if (localFiles.length) {
                      localFiles.forEach((element) => {
                        const imgData = new FormData();
                        imgData.set("FileName", element.name);
                        imgData.set("Files", element);
                        imgData.set("TicketGenerateId", res.data.id);

                        Axios.post("/TicketAttachment/AddRangeFiles", imgData, {
                          headers: { "Content-Type": "multipart/form-data" },
                        })
                          .then((res) => {
                            resetForm();
                            getAllTickets();
                            setSubmitting(false);
                            setOpen(!open);
                            setLocalFiles([]);
                          })
                          .catch((err) => {
                            resetForm();
                            getAllTickets();
                            setSubmitting(false);
                            setOpen(!open);
                            setLocalFiles([]);
                          });
                      });
                    } else {
                      resetForm();
                      getAllTickets();
                      setSubmitting(false);
                      setOpen(!open);
                      setLocalFiles([]);
                    }
                  })
                  .catch((err) => {
                    toast.error(err);
                    resetForm();
                    setSubmitting(false);
                    setOpen(!open);
                    setLocalFiles([]);
                  });
              }}
            >
              {({ isSubmitting }) => (
                <Form className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {/* First col */}
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="priority" className="block">
                        Priority
                      </label>
                      <Field
                        as="select"
                        id="priority"
                        name="priority"
                        className="block w-full border-gray-300 rounded-md shadow-sm"
                      >
                        <option value="">Select Priority</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                      </Field>
                      <ErrorMessage
                        name="priority"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    <div>
                      <label htmlFor="issues" className="block">
                        Issues
                      </label>
                      <Field
                        as="select"
                        id="issues"
                        name="issues"
                        className="block w-full border-gray-300 rounded-md shadow-sm"
                      >
                        <option value="">Select Issues</option>
                        {issues.map((issue, i) => (
                          <option value={JSON.stringify(issue)} key={i}>
                            {issue.name}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="issues"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="mt-4 ">
                      {localFiles.map((image, index) => (
                        <div
                          className="mt-2 bg-yellow-500 h-10 rounded flex justify-between "
                          key={index}
                        >
                          <div className="text-sm text-white p-4 grid place-content-center">
                            <span>{image.name}</span>
                          </div>
                          <div className="text-sm text-white p-4 grid place-content-center">
                            <i
                              className="fa-solid fa-trash-can text-white cursor-pointer"
                              onClick={() => {
                                const newLocalFiles = [...localFiles];
                                newLocalFiles.splice(index, 1);
                                setLocalFiles(newLocalFiles);
                              }}
                            ></i>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className=" mt-4">
                      <label
                        htmlFor="file"
                        className="flex items-center btn btntheme text-white px-4 py-2 rounded-md w-max"
                      >
                        <PaperclipIcon className="h-5 w-5 mr-2" />
                        Attach Files
                        <input
                          type="file"
                          name="file"
                          onChange={handleImageInput}
                          multiple
                          id="file"
                          className="hidden"
                        />
                      </label>

                      {/* <button type="button" className=""></button> */}
                    </div>
                  </div>
                  {/* Second col */}
                  <div className="flex flex-col justify-between space-y-4">
                    <div className="space-y-4">
                      <div>
                        <label htmlFor="name" className="block">
                          Title <span className="text-red-500">*</span>{" "}
                          {/* Changed label text */}
                        </label>
                        <Field
                          type="text"
                          id="name"
                          name="name"
                          className="block w-full border-gray-300 rounded-md shadow-sm"
                          placeholder="Name"
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>

                      <div>
                        <label htmlFor="description" className="block">
                          Description <span className="text-red-500">*</span>
                        </label>
                        <Field
                          as="textarea"
                          id="subject"
                          name="subject"
                          rows="4"
                          className="block w-full border-gray-300 rounded-md shadow-sm"
                          placeholder="Insert text here..."
                        />
                        <ErrorMessage
                          name="subject"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                    <div className="flex justify-end mt-6">
                      <button
                        type="submit"
                        className={`"btn btntheme text-white px-4 py-2 rounded-md ${
                          isSubmitting ? "opacity-50" : ""
                        }`}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Adding..." : "Add"}
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        }
      />

      {replyModal && (
        <div className="px-4 sm:px-6 lg:px-8 my-8">
          <Formik
            initialValues={{ subject: "" }}
            onSubmit={(val, { setFieldError, setSubmitting }) => {
              // console.log(replyItem);
              const { name, usersId, priority, id } = replyItem;
              const conversationObj = {
                name,
                usersId,
                centresId: replyItem.centresDetail.id,
                priority,
                ticketId: id,
                subject: val.subject,
              };

              const statusObj = {
                usersId,
                centresId: replyItem.centresDetail.id,
                statusId: selected.id,
                ticketId: id,
              };

              if (val.subject !== "" || localFiles.length > 0) {
                Axios.post("/Conversation/Add", conversationObj)
                  .then((res) => {
                    getAllConversation();

                    if (localFiles.length) {
                      localFiles.forEach((element) => {
                        const imgData = new FormData();
                        imgData.set("FileName", element.name);
                        imgData.set("Files", element);
                        imgData.set("ConversationId", res.data.id);
                        imgData.set("FilePath", element.name);

                        Axios.post(
                          "/ConversationAttachment/AddRangeFiles",
                          imgData,
                          {
                            headers: { "Content-Type": "multipart/form-data" },
                          }
                        )
                          .then((res) => {
                            getAllTickets();
                            setSubmitting(false);
                            setReplyModal(!replyModal);
                            setLocalFiles([]);
                          })
                          .catch((err) => {
                            getAllTickets();
                            setSubmitting(false);
                            setReplyModal(!replyModal);
                            setLocalFiles([]);
                          });
                      });
                    }
                  })
                  .catch((err) => {
                    setReplyModal(!replyModal);
                    setSubmitting(false);
                  });
              }

              Axios.post("/TicketStatus/Add", statusObj)
                .then((res) => {
                  setReplyModal(!replyModal);
                  setSubmitting(false);
                  getAllTickets();
                  getAllConversation();
                })
                .catch((err) => {
                  toast.error(err);
                  setReplyModal(!replyModal);
                  setSubmitting(false);
                });
            }}
          >
            {(formik) => (
              <>
                <div className="sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <h1 className="text-xl leading-6 font-semibold text-gray-900 flex ">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 mr-2 "
                        onClick={() => setReplyModal(false)}
                      >
                        <span className="sr-only">Close</span>
                        <i className="fa-solid fa-arrow-left"></i>
                      </button>
                      Details
                    </h1>

                    <div className="mt-2 w-full">
                      <div className="p-6 border-t">
                        <div className="border-b  p-2">
                          <span className="text-base font-semibold">
                            Title :&nbsp;
                          </span>
                          <span className="text-base">{replyItem.name}</span>
                        </div>
                        <div className="border-b p-2">
                          <span className="text-base font-semibold ">
                            Description: &nbsp;
                          </span>
                          <span className="text-base">{replyItem.subject}</span>
                        </div>
                        <DetailRow
                          label="Priority"
                          value={replyItem.priority}
                          valueclassName={`
                          ${
                            replyItem.priority === "High"
                              ? "text-red-400"
                              : replyItem.priority === "Medium"
                              ? "text-green-400"
                              : "text-yellow-400"
                          } `}
                        />
                        <DetailRow
                          label="Created At"
                          value={new Date(replyItem.createdDate).toDateString()}
                        />
                        <DetailRow
                          label="Ticket number"
                          value={replyItem.ticketNumber}
                        />
                      </div>

                      {replyItem.ticketGenerateAttachment.length > 0 && (
                        <div className="border-t">
                          <h2 className="mt-2 font-semibold text-base">
                            Attachments
                          </h2>

                          <div className="p-6  flex space-x-2">
                            {replyItem.ticketGenerateAttachment.map(
                              (file, i) => (
                                <div
                                  key={i}
                                  className="flex"
                                  onClick={() => {
                                    const url = `${baseUrl + file.filePath}`;
                                    const ext = ["jpg", "png", "jpeg"];
                                    const idxDot =
                                      file.fileName.lastIndexOf(".") + 1;
                                    const extFile = file.fileName
                                      .substr(idxDot)
                                      .toLowerCase();

                                    if (ext.includes(extFile)) {
                                      window.open(url, "_blank");
                                    } else {
                                      const a = document.createElement("a");
                                      a.href = url;
                                      a.download = ""; // Optionally, you can specify a default filename here
                                      document.body.appendChild(a);
                                      a.click();
                                      document.body.removeChild(a);
                                    }
                                  }}
                                >
                                  <div className="border p-8 group relative cursor-pointer">
                                    <div className="flex justify-center">
                                      <i className="fa-solid fa-file text-5xl text-yellow-500"></i>
                                    </div>
                                    <span className="text-base ">
                                      {file.fileName}
                                    </span>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )}

                      {specificConversation.length > 0 && (
                        <div className="p-6 border-t">
                          <h2 className="mt-2 font-semibold text-base">
                            Replies
                          </h2>

                          {specificConversation.map((message, i) => (
                            <div className="shadow-lg my-4 rounded" key={i}>
                              <div className="flex space-x-3 mb-4 border-b justify-between p-8">
                                <div className="flex">
                                  <div className="flex-shrink-0"></div>
                                  <div className="min-w-0 flex-1 ml-2">
                                    <p className="text-sm font-medium text-gray-900">
                                      <a className="hover:underline">
                                        {message.createdByName == null
                                          ? "User"
                                          : message.createdByName}
                                      </a>
                                    </p>
                                    <p className="text-sm text-gray-500">
                                      <a className="hover:underline">
                                        {message.createdByEmail == null
                                          ? "user@gmail.com"
                                          : message.createdByEmail}
                                      </a>
                                    </p>
                                  </div>
                                </div>
                                <div className="text-sm grid place-content-center">
                                  {new Date(message.createdDate).toDateString()}
                                </div>
                              </div>
                              {message.subject && (
                                <div className="flex text-base text-gray-800 p-8">
                                  {message.subject}
                                </div>
                              )}
                              {message.conversationAttachments.length > 0 && (
                                <div className="p-6  flex space-x-2">
                                  {message.conversationAttachments.map(
                                    (file, i) => (
                                      <div
                                        key={i}
                                        className="flex"
                                        onClick={() => {
                                          const url = `${
                                            baseUrl + file.filePath
                                          }`;
                                          window.open(url, "_blank");
                                        }}
                                      >
                                        <div className="border p-8 group relative cursor-pointer">
                                          <div className="flex justify-center">
                                            <i className="fa-solid fa-file text-5xl text-yellow-500"></i>
                                          </div>
                                          <span className="text-base block truncate max-w-40">
                                            {file.fileName}
                                          </span>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}

                      {role !== "admin" && selected.status == "Completed" ? (
                        <div className="bg-green-500 text-white rounded-lg px-4 py-3 flex justify-center items-center shadow-md">
                          <span className="font-medium">
                            ✅Ticket Completed
                          </span>
                        </div>
                      ) : (
                        <div className="p-6 border-t">
                          <h2 className="mt-2 font-semibold text-base">
                            Reply
                          </h2>
                          <Listbox
                            value={selected}
                            onChange={(e) => setSelected(e)}
                          >
                            {({ open }) => (
                              <>
                                <Listbox.Label className="block text-sm font-medium text-gray-700">
                                  {/* Assigned to */}
                                </Listbox.Label>
                                <div className="mt-1 relative">
                                  <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                    <div className="flex items-center">
                                      <span
                                        className={
                                          "flex-shrink-0 inline-block h-2 w-2 rounded-full"
                                        }
                                        style={{
                                          backgroundColor: selected.color,
                                        }}
                                      />
                                      {selected.status ? (
                                        <span className="ml-3 block truncate dhdfsadsklhaskdfkjjk">
                                          {selected.status}
                                        </span>
                                      ) : (
                                        <span className="ml-3 block truncate dhdfsadsklhaskdfkjjk">
                                          {selected.name}
                                        </span>
                                      )}
                                    </div>
                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none"></span>
                                  </Listbox.Button>

                                  <Transition
                                    show={open}
                                    as={React.Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                      {statuses.map((person) => (
                                        <Listbox.Option
                                          key={person.id}
                                          className={({ active }) =>
                                            classNames(
                                              // active
                                              //   ? "text-white bg-indigo-600"
                                              //   : "text-gray-900",
                                              "cursor-pointer select-none relative py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={person}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <div className="flex items-center">
                                                <span
                                                  className={`flex-shrink-0 inline-block h-2 w-2 rounded-full`}
                                                  aria-hidden="true"
                                                  style={{
                                                    backgroundColor:
                                                      person.color,
                                                  }}
                                                />
                                                <span
                                                  className={classNames(
                                                    selected
                                                      ? "font-semibold"
                                                      : "font-normal",
                                                    "ml-3 block truncate"
                                                  )}
                                                >
                                                  {person.name}
                                                </span>
                                              </div>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                          <Field
                            as="textarea"
                            id="subject"
                            name="subject"
                            rows="4"
                            className="my-2 block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 "
                            placeholder="Insert text here..."
                          ></Field>

                          <div className="mt-4 ">
                            {localFiles.map((image, index) => (
                              <div
                                className="mt-2 bg-yellow-500 h-10 rounded flex justify-between "
                                key={index}
                              >
                                <div className="text-sm text-white p-4 grid place-content-center">
                                  <span>{image.name}</span>
                                </div>
                                <div className="text-sm text-white p-4 grid place-content-center">
                                  <i
                                    className="fa-solid fa-trash-can text-white cursor-pointer"
                                    onClick={() => {
                                      const newLocalFiles = [...localFiles];
                                      newLocalFiles.splice(index, 1);
                                      setLocalFiles(newLocalFiles);
                                    }}
                                  ></i>
                                </div>
                              </div>
                            ))}
                          </div>

                          <div className=" mt-4">
                            <label
                              htmlFor="file"
                              className="flex items-center btn btntheme text-white px-4 py-2 rounded-md w-max"
                            >
                              <PaperclipIcon className="h-5 w-5 mr-2" />
                              Attach Files
                              <input
                                type="file"
                                name="file"
                                onChange={handleImageInput}
                                multiple
                                id="file"
                                className="hidden"
                              />
                            </label>

                            <button type="button" className=""></button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {!(role !== "admin" && selected.status === "Completed") && (
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="submit"
                      disabled={formik.isSubmitting}
                      onClick={formik.handleSubmit}
                      className={`btn btntheme ${
                        formik.isSubmitting ? "opacity-50" : ""
                      }`}
                    >
                      {formik.isSubmitting ? "Sending..." : "Send"}
                    </button>
                  </div>
                )}
              </>
            )}
          </Formik>
        </div>
      )}

      <Modal
        open={deleteModal}
        setOpen={setDeleteModal}
        width={"max-w-screen-xl"}
        content={
          <Formik
            initialValues={{ name: replyItem.name, color: replyItem.color }}
            validationSchema={yup.object().shape({
              name: yup.string().required("Status name is required"),
              color: yup.string().required("Color is required"),
            })}
            onSubmit={(val, { setFieldError, setSubmitting }) => {
              val.id = replyItem.id;
              Axios.put("/Status/Update", val)
                .then((res) => {
                  setReplyModal(!replyModal);
                  setSubmitting(false);
                  getAllTickets();
                })
                .catch((err) => {
                  setFieldError("color", err);
                  setSubmitting(false);
                });
            }}
          >
            {(formik) => (
              <>
                <div className="sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                      </button>
                    </div>
                    <div className="sm:items-start">
                      <div className="mt-3 text-center sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-medium text-gray-900 flex justify-between "
                        >
                          <div className="flex space-x-2">
                            <ExclamationTriangleIcon
                              className="h-6 w-6 text-red-600"
                              aria-hidden="true"
                            />
                            <span>Delete Status</span>
                          </div>
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close</span>
                          </button>
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700  sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => deleteStatus()}
                      >
                        {deleteloading ? (
                          <>
                            <Loader size={"size-4"} /> Deleteing...
                          </>
                        ) : (
                          "Delete"
                        )}
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
                        onClick={() => setDeleteModal(false)}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Formik>
        }
      />
    </>
  );
}

const DetailRow = ({ label, value, valueClass = "" }) => {
  return (
    <div className="flex  border-b p-2">
      <span className="text-base font-semibold">{label}:</span>
      <span className={`text-base ${valueClass} ml-3`}>{value}</span>
    </div>
  );
};
export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};
