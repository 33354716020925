import React from "react";
import { Storage } from "../../Services/Storage";
import { Axios } from "../../Services/Axios";
import { toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";
import {
  XMarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import Modal from "../../Components/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import Loader from "../../Components/loader";
import { fetchPermissions } from "../../Components/sidebar";

export default function Index() {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [deleteloading, setDeleteLoading] = React.useState(false);
  const [editModal, setEditModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [permissions, setPermissions] = React.useState([]);
  const [issues, setIssues] = React.useState([]);
  const [editIssue, setEditIssue] = React.useState([]);
  const [deleteId, setDeleteId] = React.useState("");

  const id = Storage.getItem("user").id;

  const getAllIssues = () => {
    Axios.get("/IssueType/GetAll")
      .then((res) => {
        setIssues(res.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  const deleteIssue = () => {
    setDeleteLoading(true);
    Axios.delete(`/IssueType/Remove?id=${deleteId}`)
      .then((res) => {
        getAllIssues();
        setDeleteModal(false);
        setDeleteLoading(false);
      })
      .catch((err) => {
        setDeleteLoading(false);
        toast.error(err);
      });
  };

  React.useEffect(() => {
    fetchPermissions(id)
      .then((res) => {
        const permission = res.find((item) => item.title == "Issues");
        // console.log(permission);
        setPermissions(permission);
      })
      .catch((err) => {
        toast.error("Uncaught error");
      });

    getAllIssues();
  }, []);
  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8 my-4">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Issues</h1>
            <p className="mt-2 text-sm text-gray-700">
              Here are the current issues for your account
            </p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            {permissions.canAdd && (
              <button
                type="button"
                className="btn btntheme"
                onClick={() => setOpen(!open)}
              >
                Add Issue
              </button>
            )}
          </div>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Issue Tag
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Issue label
                      </th>
                      {permissions.canUpdate || permissions.canDelete ? (
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Action
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {loading && (
                      <tr>
                        <td className="text-base p-4">Loading...</td>
                      </tr>
                    )}
                    {issues.length <= 0 && !loading && (
                      <tr>
                        <td className="text-base p-4 text-red-500">
                          No Issue available
                        </td>
                      </tr>
                    )}
                    {issues.map((issue, i) => (
                      <tr key={i}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {issue.tag}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {issue.name}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                          {permissions?.canUpdate && (
                            <span
                              className="mx-2"
                              onClick={() => {
                                setEditModal(true);
                                setEditIssue(issue);
                              }}
                            >
                              <i className="fa-solid fa-pen-to-square"></i>
                            </span>
                          )}
                          {permissions?.canDelete && (
                            <span
                              className="mx-2"
                              onClick={() => {
                                setDeleteId(issue.id);
                                setDeleteModal(true);
                              }}
                            >
                              <i className="fa-solid fa-trash-can"></i>
                            </span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        setOpen={setOpen}
        width={"max-w-2xl"}
        content={
          <Formik
            initialValues={{ Tag: "", Name: "" }}
            validationSchema={yup.object().shape({
              Tag: yup.string().required("Issue tag is required"),
              Name: yup.string().required("Issue label required"),
            })}
            onSubmit={(val, { setFieldError, setSubmitting }) => {
              Axios.post(`/IssueType/Add`, val)
                .then((res) => {
                  setOpen(!open);
                  setSubmitting(false);
                  getAllIssues();
                })
                .catch((err) => {
                  setFieldError("issueLabel", err);
                  setSubmitting(false);
                });
            }}
          >
            {(formik) => (
              <form>
                <div className="sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-semibold text-gray-900 flex justify-between"
                    >
                      Add Issue
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Issue Tag
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            id="tag"
                            name="Tag"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Tag"
                            value={formik.values.Tag}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.Tag && formik.errors.Tag ? (
                          <div className="text-red-500 text-sm">
                            {formik.errors.Tag}
                          </div>
                        ) : null}
                      </div>
                      <div className="my-4">
                        <label
                          htmlFor="color"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Issue Label
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            id="name"
                            name="Name"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Label"
                            value={formik.values.Name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.Name && formik.errors.Name ? (
                          <div className="text-red-500 text-sm">
                            {formik.errors.Name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    onClick={formik.handleSubmit}
                    className={`btn btntheme ${
                      formik.isSubmitting ? "opacity-50" : ""
                    } `}
                  >
                    {formik.isSubmitting ? "Adding..." : "Add"}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        }
      />
      <Modal
        open={editModal}
        setOpen={setEditModal}
        width={"max-w-2xl"}
        content={
          <Formik
            initialValues={{ name: editIssue.name, tag: editIssue.tag }}
            validationSchema={yup.object().shape({
              tag: yup.string().required("Status name is required"),
              name: yup.string().required("Color is required"),
            })}
            onSubmit={(val, { setFieldError, setSubmitting }) => {
              val.id = editIssue.id;
              Axios.put("/IssueType/Update", val)
                .then((res) => {
                  setEditModal(!editModal);
                  setSubmitting(false);
                  getAllIssues();
                })
                .catch((err) => {
                  setFieldError("color", err);
                  setSubmitting(false);
                });
            }}
          >
            {(formik) => (
              <>
                <div className="sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-semibold text-gray-900 flex justify-between"
                    >
                      Update Status
                      <button
                        type="button"
                        className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                        onClick={() => setEditModal(false)}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </Dialog.Title>
                    <div className="mt-2 w-full">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Issue Tag
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            id="name"
                            name="tag"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            placeholder="Status"
                            value={formik.values.tag}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.tag && formik.errors.tag ? (
                          <div className="text-red-500 text-sm">
                            {formik.errors.tag}
                          </div>
                        ) : null}
                      </div>
                      <div className="my-4">
                        <label
                          htmlFor="color"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Issue Label
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            id="name"
                            name="name"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.name && formik.errors.name ? (
                          <div className="text-red-500 text-sm">
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    disabled={formik.isSubmitting}
                    onClick={formik.handleSubmit}
                    className={`btn btntheme ${
                      formik.isSubmitting ? "opacity-50" : ""
                    } `}
                  >
                    {formik.isSubmitting ? "Updating..." : "Update"}
                  </button>
                </div>
              </>
            )}
          </Formik>
        }
      />

      <Modal
        open={deleteModal}
        setOpen={setDeleteModal}
        width={"max-w-2xl"}
        content={
          <div className="sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:text-left">
              <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                </button>
              </div>
              <div className="sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900 flex justify-between "
                  >
                    <div className="flex space-x-2">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                      <span>Delete Status</span>
                    </div>
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                    </button>
                  </Dialog.Title>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={`inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700  sm:ml-3 sm:w-auto sm:text-sm ${
                    deleteloading ? "opacity-50" : ""
                  }`}
                  onClick={() => deleteIssue()}
                >
                  {deleteloading ? (
                    <>
                      <Loader size={"size-4"} /> Deleteing...
                    </>
                  ) : (
                    "Delete"
                  )}
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setDeleteModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        }
      />
    </>
  );
}
