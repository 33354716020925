import React from "react";

const WelcomePage = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gradient-to-r from-yellow-100 via-yellow-200 to-yellow-300">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md text-center">
        <h1 className="text-3xl font-semibold text-gray-800 mb-4">
          Welcome to the Aptech Dashboard
        </h1>
        <p className="text-gray-600 mb-6">
          Here you can manage all aspects of the application, review reports,
          and make administrative changes.
        </p>
      </div>
    </div>
  );
};

export default WelcomePage;
